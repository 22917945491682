import { useState } from "react";

const faqs = [
    {
        question: 'How does the app help reduce my energy consumption?',
        answer: 'Our app provides real-time monitoring and personalized tips to help you understand and optimize your energy usage, leading to significant reductions in consumption.'
    },
    {
        question: 'Can I connect with others to form a community?',
        answer: 'Yes! Our platform encourages users to connect, share insights, and collaborate on energy-saving strategies, fostering a supportive community of eco-conscious individuals.'
    },
    {
        question: 'Is there a cost to using the app?',
        answer: 'The basic version of our app is free to use. We offer premium features at a minimal cost, which further enhance your ability to save energy and money.'
    },
    {
        question: 'How can I track my energy savings over time?',
        answer: 'The app features a comprehensive dashboard that tracks your energy consumption and savings in real-time, allowing you to view your progress daily, monthly, and annually.'
    },
    {
        question: 'What makes this app different from other energy-saving apps?',
        answer: 'Our unique community-driven approach not only helps you save energy but also connects you with a network of users for shared tips, challenges, and achievements, making saving energy a collaborative effort.'
    },
    {
        question: 'Can the app control smart home devices to save energy?',
        answer: 'While the current version focuses on monitoring and advice, future updates are planned to integrate with smart home devices, allowing for automatic adjustments to optimize energy use.'
    }
];

export default function Faqs() {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleFAQ = index => {
        setActiveIndex(index === activeIndex ? null : index);
    };
    return (
        <section className="py-10">
            <div className="container mx-auto px-4">
                <h2 className="text-center text-2xl font-bold mb-5">Frequently Asked Questions</h2>
                <div>
                    {faqs.map((faq, index) => (
                        <div
                            key={index}
                            className={`my-2 p-4 bg-white shadow-md rounded-lg cursor-pointer transition duration-300 ease-in-out ${index === activeIndex ? 'bg-gray-100' : ''}`}
                            onClick={() => toggleFAQ(index)}
                        >
                            <h3 className="font-semibold">{faq.question}</h3>
                            <p className={`mt-2 ${index === activeIndex ? 'block' : 'hidden'}`}>{faq.answer}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}