import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import App from "./routes/App";
import MemberDashboard from "./routes/MemberDashboard/MemberDashboard";
import Login from "./routes/Login/Login";
import Demo from "./routes/Demo";
import Whoops404 from "./components/Whoops404";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import WelcomePage from "./routes/WelcomePage/Principal/WelcomePage";
import './i18n';
import Settings from "./routes/Settings/Settings";
import Users from "./routes/Settings/Users";
import Invite from "./routes/Invites/Invite";
import DeviceActivation from "./routes/Device/DeviceActivation";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <div>Error met.</div>,
    children: [
      {
        path: "welcome",
        element: <WelcomePage />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "devices/activate",
        element: <DeviceActivation />,
      },
      {
        path: "invites/:inviteId",
        element: <Invite />,
      },
      {
        path: "settings",
        element: <Settings />,
        children: [
          // {
          //   path: "profile",
          //   element: <Profile />,
          // },
          // {
          //   path: "security",
          //   element: <Security />,
          // },
          // {
          //   path: "preferences",
          //   element: <Preferences />,
          // },
          {
            path: "users",
            element: <Users />,
          },
          // {
          //   path: "members",
          //   element: <Members />,
          // },
        ]
      },
      {
        path: "demo",
        element: <Demo />,
      },
      {
        path: "members",
        element: <MemberDashboard />,
        children: [
          {
            path: ":id",
            element: <MemberDashboard />,
            children: [
              {
                path: ":granularity",
                element: <MemberDashboard />,
                children: [
                  {
                    path: ":date",
                    element: <MemberDashboard />,
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: "/index.html",
        redirect: "/"
      },
      {
        path: "*",
        element: <Whoops404 />
      },
    ]
  }
]);

ReactDOM.createRoot(document.getElementById("app")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

serviceWorkerRegistration.unregister();
// serviceWorkerRegistration.register();
