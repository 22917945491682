import React, { useEffect } from 'react';
import useFetch from '../hooks/useFetch';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Menu, Transition } from '@headlessui/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Selector({ element, setElement, type, url }) {
  const { data: elements, error } = useFetch(url);

  function handleChange(selectedElement) {
    console.log('selected ' + type + ': ' + JSON.stringify(selectedElement));
    setElement(selectedElement);
  }

  useEffect(() => {
    if (elements && elements.length === 1) {
      let [firstElement] = elements;
      if (!element || element.id !== firstElement.id) {
        console.log('only one ' + type + ' ' + JSON.stringify(firstElement));
        setElement(firstElement);
      }
    }
  }, [elements, element, setElement, type]);

  if (error == null && elements && elements.length > 1) {
    return (
      <Menu as="div" className="relative text-left">
        <Menu.Button className="inline-flex justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-amber-900">
          {element ? element.name : `Select a ${type}...`}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
         <Menu.Items className="origin-top-left absolute mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
            <div className="py-1">
              {elements.map((item) => (
                <Menu.Item key={item.id}>
                  {({ active }) => (
                    <button
                      type="button"
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block w-full text-left px-4 py-2 text-sm'
                      )}
                      onClick={() => handleChange(item)}
                    >
                      {item.name ? item.name : item.id}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  }
}