import React, { useState, useRef, useContext, createContext, useCallback } from "react";
import Error from "../components/Error";

const errorsContext = createContext();
const ERROR_COOLDOWN_PERIOD = 2 * 60 * 1000;

export function ProvideErrors({ children }) {
  const errors = useProvideErrors();
  return <errorsContext.Provider value={errors}>{children}</errorsContext.Provider>;
}

export function Errors() {
  const e = useContext(errorsContext);
  if (!e.errors || e.errors.length === 0 || e.showPopup === false) return (<></>);
  return <Error
    messages={Object.entries(e.errors).map(([error, num]) => error + " [" + num + "]")}
    closeCallback={e.clean} />;
}

export function useErrors() {
  return { add: useContext(errorsContext).add };
}

function useProvideErrors() {
  const [errors, setErrors] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const errorTimers = useRef({});

  const add = useCallback((error) => {
    const now = Date.now();

    let newErrorCount;
    if (showPopup === false && (!errorTimers.current[error] || now - errorTimers.current[error] > ERROR_COOLDOWN_PERIOD)) {
      newErrorCount = 1;
      setShowPopup(true);
    } else {
      newErrorCount = (errors[error] || 0) + 1;
    }
    errorTimers.current[error] = now;
    setErrors(prevErrors => ({
      ...prevErrors,
      [error]: newErrorCount
    }));
  }, [showPopup, errors, errorTimers]);

  const clean = useCallback(() => {
    setShowPopup(false);
    const now = Date.now();
    const newErrors = { ...errors };

    Object.entries(errorTimers.current).forEach(([error, timestamp]) => {
      if (now - timestamp > ERROR_COOLDOWN_PERIOD) {
        delete errorTimers.current[error];
        delete newErrors[error];
      }
    });

    setErrors(newErrors);
  }, [errors, errorTimers]);

  return {
    add,
    clean,
    errors,
    showPopup
  };
}