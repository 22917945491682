import moment from 'moment-timezone';

export function formatDate(time) {
  const now = new Date();
  const timeDate = new Date(time);
  const adjustedTime = timeDate > now ? now : timeDate;
  return moment(adjustedTime).fromNow();
}

export function getStartOfDay(date) {
  return moment(date).startOf('day');
}

export function getStartOfDayEpochMillis(date) {
  return getStartOfDay(date).unix() * 1000;
}

export function getEndOfDay(date) {
  return moment(getEndOfDayEpochMillis(date));
}

export function getEndOfDayEpochMillis(date) {
  return getStartOfDayEpochMillis(date) + (60 * 60 * 24 * 1000);
}

export function getStartOfMonth(date) {
  return moment(date).startOf('month');
}

export function getStartOfYear(date) {
  return moment(date).startOf('year');
}

export function getEndOfMonth(date) {
  return moment(getEndOfMonthEpochMillis(date));
}

export function getEndOfYear(date) {
  return moment(getEndOfYearEpochMillis(date));
}

export function getEndOfMonthEpochMillis(date) {
  return getStartOfMonth(date).add(1, 'month').unix() * 1000;
}

export function getEndOfYearEpochMillis(date) {
  return getStartOfYear(date).add(1, 'year').unix() * 1000;
}

export function getDateFromRouteParam(dateFromRouteParam, granularity) {
  if (!dateFromRouteParam) {
    return new Date();
  }
  const [year, month, day] = dateFromRouteParam.split("-").map(Number);
  const now = new Date();
  switch (granularity) {
    case "year":
      if (year === now.getFullYear()) {
        return now;
      }
      return new Date(year, 0, 1);
    case "month":
      if (year === now.getFullYear() && month === (now.getMonth() + 1)) {
        return now;
      }
      return new Date(year, month - 1, 1);
    case "day":
      return new Date(year, month - 1, day);
    default:
      console.error("Unsupported granularity value!");
      return null;
  }
}

export function formatDateForUrl(date, granularity) {
  switch (granularity) {
    case "year":
      return `${date.getFullYear()}`;
    case "month":
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
    case "day":
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    default:
      console.error("Unsupported granularity value!");
      return null;
  }
}
