import UserMemberDashboard from "./UserMemberDashboard";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useContext } from 'react';
import { MemberContext, SetMemberContext } from '../../member-context';
import { useAuth } from "../../hooks/useAuth";
import translationEN from './translations/en.json';
import translationFR from './translations/fr.json';
import translationNL from './translations/nl.json';
import useComponentTranslation from '../../hooks/useComponentTranslations';
import CommunitySelector from "../../components/CommunitySelector";
import MemberSelector from "../../components/MemberSelector";
import { CommunityContext, SetCommunityContext } from "../../community-context";

function MemberDashboard() {
  const { t } = useComponentTranslation('MemberDashboard', { 'en': translationEN, 'fr': translationFR, 'nl': translationNL });

  const auth = useAuth();
  const member = useContext(MemberContext);
  const community = useContext(CommunityContext);
  const setMember = useContext(SetMemberContext);
  const setCommunity = useContext(SetCommunityContext);

  const routeParams = useParams();
  const memberId = routeParams.id;

  const navigate = useNavigate();

  useEffect(() => {
    if (!memberId) {
      if (member) {
        navigate('/members/' + member.id);
      }
    }
  }, [memberId, member, navigate]);


  return (
    <div>
      {auth.user && (
        <>
          {!community && (
            <div className="flex justify-center my-8 py-3">
              <CommunitySelector setCommunity={setCommunity} />
            </div>
          )}
          {community && !member && (
            <div className="flex justify-center my-8 py-3">
              <MemberSelector setMember={setMember} community={community} />
            </div>
          )}
        </>
      )}

      {auth.user && memberId && (

        <UserMemberDashboard t={t} />
      )}

    </div>
  );
}

export default MemberDashboard;
