import { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { CommunityContext, SetCommunityContext } from '../community-context';
import { MemberContext, SetMemberContext } from '../member-context';
import { ProvideAuth } from "../hooks/useAuth.js";
import Navbar from './common/Navbar/Navbar';

import { Errors, ProvideErrors } from '../hooks/useErrors';

function Layout({ children }) {
  return <main className="p-0">{children}</main>;
}

function AppProvider({ children }) {
  //TODO: improve code duplication
  const [community, setCommunity] = useState(null);
  const [member, setMember] = useState(null);

  const navigate = useNavigate();

  function afterLogout() {
    setMember(null);
    setCommunity(null);
    navigate('/login');
  }

  return (
    <ProvideAuth logoutCallback={() => afterLogout()}>
      <CommunityContext.Provider value={community}>
        <SetCommunityContext.Provider value={setCommunity}>
          <MemberContext.Provider value={member}>
            <SetMemberContext.Provider value={setMember}>
              {children}
            </SetMemberContext.Provider>
          </MemberContext.Provider>
        </SetCommunityContext.Provider>
      </CommunityContext.Provider>
    </ProvideAuth>
  );
}

export default function App() {
  return (
    <ProvideErrors>
      <AppProvider>
        <Layout>
          <Navbar />
          <div className="mt-16" id='detail'>
            <Outlet />
            <Errors />
          </div>
        </Layout>
      </AppProvider>
    </ProvideErrors >
  );
}
