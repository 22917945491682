export default function GranularitySelector({ onChange, t }) {

  const granularities = ['day', 'month', 'year'];

  function select(granularity) {
    if (onChange) onChange(granularity);
  }
  return (
    <div className="flex mx-auto w-min items-center justify-center gap-1">
      {granularities.map(granularity => (
        <button
          key={granularity}
          type="button"
          className="justify-center rounded-md border bg-white px-3 py-1 text-sm font-medium text-amber-900 shadow-sm border-amber-900"
          onClick={() => select(granularity)}
        >
          {t('MemberDashboard:' + granularity)}
        </button>
      ))}
    </div>
  );
};