import React, { useState, useEffect, useContext, createContext, useCallback } from "react";

const authContext = createContext();

export function ProvideAuth({ children, logoutCallback }) {
  const auth = useProvideAuth(logoutCallback);
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export function useAuth() {
  return useContext(authContext);
}

function useProvideAuth(logoutCallback) {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [, setHasLoggedOut] = useState(false);

  function login(email) {
    return fetch('/login/demo?' +
      new URLSearchParams({ email }), { method: 'POST' }).then(() => {
        console.log("login success for user " + email);
        //TODO: log error in case
        fetchCurrentUser();
      });
  }

  function fetchCurrentUser() {
    return fetch('/api/users/me')
      .then(res => {
        if (res.status === 401) {
          if (user) {
            logout();
          }
          return Promise.reject(new Error('Unauthorized'));
        }
        if (!res.ok) {
          return Promise.reject(new Error(res.statusText));
        }
        return res.json();
      })
      //TODO: treat result in another method (here only fetching)
      .then((result) => {
        setUser(result);
        sessionStorage.setItem("user", JSON.stringify(result));
      });
  }

  const logout = useCallback(() => {
    return fetch('/logout', { method: 'POST' })
      .finally(() => {
        setUser(null);
        sessionStorage.clear();
        // TODO: check for which case hasLoggedOut could be used (redirect in a useEffect ?)
        setHasLoggedOut(true);
      }).finally(() => {
        sessionStorage.clear();
        if (logoutCallback) logoutCallback();
      });
  }, [logoutCallback]);

  useEffect(() => {
    async function initializeAuthState() {
      setLoading(true);
      try {
        let sessionUserJson = sessionStorage.getItem("user");
        if (sessionUserJson) {
          let sessionUser = JSON.parse(sessionUserJson);
          setUser(sessionUser);
        } else {
          //TODO:avoid logging error when not authenticated
          await fetchCurrentUser().catch((err) => console.error(err));
        }
      } catch (err) {
        console.error("Error initializing auth state:", err);
      } finally {
        setLoading(false);
      }
    }

    initializeAuthState();
  }, []);

  return {
    user,
    error,
    loading,
    login,
    logout,
  };
}

