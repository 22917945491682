import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';

export default function Error({ messages, closeCallback }) {
  let [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (messages && messages.length > 0) setIsOpen(true);
    else setIsOpen(false);
  }, [messages]);

  function closeModal() {
    setIsOpen(false);
    if (closeCallback) closeCallback();
  }

  return (isOpen &&
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-30" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all ">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-amber-900"
                >
                  Whoops
                </Dialog.Title>
                <div className="mt-2">
                  {messages && messages.map((msg, index) => (
                    <p key={index} className="text-sm text-amber-600">
                      {msg}
                    </p>
                  ))}
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="focus:outline-none h-10 box-border justify-center rounded-md border border-amber-900 bg-amber-500 px-4 py-2 text-sm font-bold text-amber-900 shadow-sm"
                    onClick={closeModal}
                  >
                    Got it, thanks!
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
