import React, { useRef } from 'react';
import Invitation from '../Invitation/Invitation';
import ProfileQuestions from '../ProfileQuestions/ProfileQuestions';
import ProfileDetails from '../ProfileDetails/ProfileDetails';
import communityPictogram from '../images/community_pictogram.webp';
import savings from '../images/savings.webp';
import sustainability from '../images/sustainability.webp';
import Faqs from '../Faqs/Faqs';
import Footer from '../Footer/Footer';


function WelcomePage() {

    const sectionRefs = useRef({});

    const assignRef = (key) => {
        if (!sectionRefs.current[key]) {
            sectionRefs.current[key] = React.createRef();
        }
        return sectionRefs.current[key];
    };

    const renderDetail = (detail) => {
        if (Array.isArray(detail)) {
            return (
                <ul className="list-disc ml-8 pl-4">
                    {detail.map((item, index) => (
                        <li key={index} className="text-md">{item}</li>
                    ))}
                </ul>
            );
        }
        return <p className="text-md">{detail}</p>;
    };

    const scrollToDetail = (key) => {
        const ref = sectionRefs.current[key];
        if (ref && ref.current) {
            const topPos = ref.current.getBoundingClientRect().top + document.documentElement.scrollTop;
            const offset = 50;
            window.scrollTo({ top: topPos - offset, behavior: "smooth" });
        }
    };

    return (
        <div>
            <Invitation scrollToDetail={scrollToDetail} />
            <ProfileQuestions scrollToDetail={scrollToDetail} assignRef={assignRef} />
            <ProfileDetails renderDetail={renderDetail} assignRef={assignRef} />
            <div className="my-8">
                <h2 className="py-4 text-center text-3xl font-bold mb-8 text-gray-700">Our Mission</h2>
                <Feature
                    image={sustainability}
                    title="Sustainable Living"
                    description="Make a difference with your energy use. Simple changes in how we consume energy can significantly reduce CO2 emissions. Join us in the practical shift towards efficiency."
                    imageLeft={true}
                />
                <Feature
                    image={savings}
                    title="Your Energy, Your Control"
                    description="Let's tackle energy costs head-on, making choices that keep more money in your pocket. It's about smart management and local collaboration for a more predictable budget."
                    imageLeft={false}
                />
                <Feature
                    image={communityPictogram}
                    title="Build Together"
                    description="Build a brighter, greener future together. Join a community where innovation meets sustainability, and every action counts."
                    imageLeft={true}
                />
            </div>

            <Faqs />

            <Footer />
        </div>
    );
}

const Feature = ({ image, title, description, imageLeft }) => (
    <div className="my-8">
        <section className={`flex ${imageLeft ? 'flex-col md:flex-row' : 'flex-col md:flex-row-reverse'} items-center justify-center gap-8 md:gap-28 h-[70vh]`}>
            <img src={image} alt={title} className="w-full h-auto max-w-xs" />
            <div className="w-full md:w-1/2 lg:w-1/3 px-4 text-center">
                <h3 className="text-xl md:text-2xl lg:text-3xl font-bold text-green-600 mb-2">{title}</h3>
                <p className="text-base md:text-lg lg:text-xl text-gray-700">{description}</p>
            </div>
        </section>
        <div className="w-full h-2 bg-gray-700 my-8"></div>
    </div>
);

export default WelcomePage;

