import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import Connect from "./Connect";

export default function Invite() {
    const routeParams = useParams();
    const inviteId = routeParams.inviteId;
    const { user, loading } = useAuth();
    const currentUrlWithParams = window.location.href;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [invite, setInvite] = useState(null);
    const [isValidInvite, setIsValidInvite] = useState(false);


    const navigate = useNavigate();

    const acceptInvite = async () => {
        try {
            const response = await fetch(`/api/invites/${inviteId}/accept`, {
                method: 'POST'
            });
            if (!response.ok) {
                throw new Error('Failed to accept invite');
            }
            navigate('/members');
        } catch (error) {
            console.error('Error accepting invite:', error);
        }
    };

    useEffect(() => {
        if (!loading && !user) {
            setIsModalOpen(true);
        }
    }, [user, loading]);

    useEffect(() => {
        if (user && !loading) {
            validateInvite(inviteId).then(response => {
                setIsModalOpen(false);
                if (response.isValid) {
                    setIsValidInvite(true);
                    setInvite(response);
                } else {
                    console.error('Invalid invite');
                }
            }).catch(error => console.error('Error validating invite:', error));
        }
    }, [user, loading, inviteId, navigate]);

    const validateInvite = async (inviteId) => {
        try {
            const response = await fetch(`/api/invites/${inviteId}/validate`, {
                method: 'POST'
            });
            const data = await response.json();
            if (!response.ok) {
                console.error('Failed to validate invite:', data.error || 'Unknown error');
                return { isValid: false };
            }
            return {
                isValid: true,
                ...data
            };
        } catch (error) {
            console.error('Error validating invite:', error);
            return { isValid: false };
        }
    };

    return (
        <div className="bg-gray-100 min-h-screen flex">
            <div className="container mx-auto px-4 lg:px-8 py-8">
                {isModalOpen && <Connect isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} redirectPath={currentUrlWithParams} />}
                {isValidInvite && (
                    <div className="max-w-lg mx-auto bg-white rounded-lg shadow overflow-hidden">
                        <div className="px-6 py-8">
                            <h2 className="text-2xl font-semibold text-amber-900 mb-4">You're Invited!</h2>
                            <p className="text-md text-gray-700">Hi {invite.fullName},</p>
                            <p className="text-md text-gray-700 mb-5">You've been invited to join "{invite.memberName}". Click below to accept the invite and start your journey.</p>
                            <button
                                className="w-full bg-amber-600 text-white rounded-md px-4 py-2 hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2 transition ease-in-out duration-150"
                                onClick={acceptInvite}
                            >
                                Accept
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>

    );
};
