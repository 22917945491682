import { PlusIcon, Bars2Icon } from '@heroicons/react/24/outline';
import React from "react";
import { formatDate } from '../utils/dateUtils';
import DataAccessStatuses from './DataAccessStatuses';

export default function LastPowerStats({ data, metadata, dataAccessStatus = DataAccessStatuses.LOADING, t }) {

  return (
    <div className="bg-amber-50 border-b border-b-amber-900 text-amber-900 w-full m-auto text-center">
      <div className={(dataAccessStatus === DataAccessStatuses.ERROR_NO_DATA ?
        "invisible " :
        "") + "w-full lg:grid lg:grid-cols-2 space-y-1 lg:space-x-8 lg:space-y-0 py-1"}>
        {Object.keys(metadata).map((category) => {
          return (
            <div key={category + "0"} className="w-[86%] lg:w-full flex flex-row justify-center text-[10px] lg:text-sm xl:text-base">
              <div key={category + "1"} className="flex items-start p-0 lg:p-1">
                <div key={category + "2"}>
                  <div key={category + "3"} className="whitespace-nowrap">{metadata[category]['label']}</div>
                  <div key={category + "4"} className={"text-gray-500 text-[12px] lg:text-2xl"}>{Object.keys(metadata[category]['fields']).map(field => data[field]).reduce(((a, b) => a + b))}</div>
                  <div key={category + "5"} >{metadata[category]['unit']}</div>
                </div>
              </div>

              <div key={category + "6"} className="flex self-center px-0.5 lg:p-1">
                <Bars2Icon key={category + "7"} className="h-3 w-3 lg:h-4 lg:w-4" />
              </div>

              {Object.keys(metadata[category]['fields']).map((field, index, row) => {
                return (
                  <React.Fragment key={field + "0"}>
                    <div key={field + "1"} className="flex items-start p-0 lg:p-1">
                      <div key={field + "2"}>
                        <div key={field + "3"} className="whitespace-nowrap">{metadata[category]['fields'][field]['label']}</div>
                        <div key={field + "4"} className={"text-[12px] lg:text-2xl  text-[" + metadata[category]['fields'][field]['color'] + "]"}>{data[field]}</div>
                        <div key={field + "5"} >{metadata[category]['fields'][field]['unit']}</div>
                      </div>
                    </div>
                    {
                      (index !== row.length - 1) &&
                      <div key={field + "6"} className="flex self-center px-0.5 lg:p-1">
                        <PlusIcon key={field + "7"} className="h-3 w-3 lg:h-4 lg:w-4" />
                      </div>
                    }
                  </React.Fragment>
                );
              })
              }
              <div key={category + "8"} className=" w-[14%] lg:hidden flex flex-row text-[7px] absolute right-1 p-1 lg:p-3">
                <div key={category + "9"}>
                  <div key={category + "10"}>{t('MemberDashboard:refreshed') + ' '}</div>
                  <div key={category + "11"}>{getLastRefreshTime(category)}</div>
                </div>
              </div>
            </div>

          );
        })}
        {Object.keys(metadata).map((category) => {
          return (
            <div key={category + "12"} className="hidden lg:block flex flex-row text-[12px]">
              <div key={category + "13"}>
                <div key={category + "14"}>{t('MemberDashboard:refreshed') + ' ' + getLastRefreshTime(category)}</div>
              </div>
            </div>
          );
        })}

      </div>
      <div className={(dataAccessStatus === DataAccessStatuses.ERROR_NO_DATA ? "" : "hidden ") + "flex flex-row justify-center lg:col-span-2 text-red-700"}>{t('MemberDashboard:unable_to_fetch')}</div>
      <div className={(dataAccessStatus === DataAccessStatuses.ERROR_STALE_DATA ? "" : "hidden ") + "flex flex-row justify-center lg:col-span-2 text-red-700"} >{t('MemberDashboard:unable_to_update')}</div>
    </div>
  );

  function getLastRefreshTime(category) {
    return formatDate(data[metadata[category]['timeField']]);
  }
}