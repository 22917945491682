import { useContext } from 'react';
import { CommunityContext } from '../community-context';
import { useAuth } from '../hooks/useAuth';
import Selector from './Selector';

export default function CommunitySelector({ setCommunity }) {
  const community = useContext(CommunityContext);
  const auth = useAuth();
  if (!auth.user) return;
  return (
    <Selector
      element={community}
      setElement={setCommunity}
      type='community'
      url='/api/communities/'
    />);
}
