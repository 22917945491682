import { Dialog, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import moment from "moment-timezone";
import { Fragment, useEffect, useState } from "react";
import { Calendar } from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import './CalendarSelector.css';
import { useTranslation } from "react-i18next";

export default function CalendarSelector({ onChange, value, granularity, t }) {
  let [isOpen, setIsOpen] = useState(false);
  let [date, setDate] = useState(value);

  const { i18n } = useTranslation();

  useEffect(() => {
    setDate(moment(value).toDate());
  }, [i18n.language]);

  const { view, format } = getParams(granularity);
  const commonButtonLayout = "justify-center rounded-md border bg-white px-2 py-1 text-sm font-medium text-amber-900 shadow-sm border-amber-900";

  function today() {
    onChange(new Date());
  }

  function closeModal() {
    setIsOpen(false);
    setDate(value);
  }

  function select() {
    setIsOpen(false);
    onChange(date);
  }

  function prev() {
    const prevDate = moment(value).subtract(1, granularity).toDate();
    setDate(prevDate);
    onChange(prevDate);
  }

  function next() {
    const nextDate = moment(value).add(1, granularity).toDate();
    setDate(nextDate);
    onChange(nextDate);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <div className="flex mx-auto items-center justify-center gap-1">
        <button
          type="button"
          className={commonButtonLayout}
          onClick={today}
        >
          {t('MemberDashboard:today')}
        </button>
        <button
          type="button"
          className={commonButtonLayout}
          onClick={prev}
        >
          <ChevronDownIcon className="h-5 w-5 rotate-90" aria-hidden="true" />
        </button>
        <button
          type="button"
          onClick={openModal}
          className={"w-40 whitespace-nowrap " + commonButtonLayout}
        >
          {moment(value).format(format)}
        </button>
        <button
          type="button"
          disabled={moment(value).add(1, granularity).isAfter(moment())}
          className={"disabled:border-amber-300 disabled:text-amber-300 " + commonButtonLayout}
          onClick={next}
        >
          <ChevronDownIcon className="h-5 w-5 -rotate-90" aria-hidden="true" />
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">

                  <Calendar onChange={setDate} value={date} view={view} maxDate={new Date()} maxDetail={view} />
                  <div className="mt-4 ml-auto mr-0 items-center w-min">
                    <button
                      type="button"
                      className={commonButtonLayout}
                      onClick={select}
                    >
                      Select
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

function getParams(granularity) {
  switch (granularity) {
    case "year":
      return {
        view: "decade",
        format: "YYYY"
      };
    case "month":
      return {
        view: "year",
        format: "MMMM YYYY"
      };
    default:
      return {
        view: "month",
        format: "LL"
      };
  }
}