import { React, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment-timezone';
import * as d3 from 'd3';
import { XAxis, YAxis, ResponsiveContainer, Legend, CartesianGrid, Tooltip, ReferenceArea, AreaChart, Area } from 'recharts';
import { getStartOfDayEpochMillis } from '../../utils/dateUtils';
import { createUseGesture, pinchAction, dragAction } from '@use-gesture/react';
import useIsMobile from '../../hooks/useIsMobile';
import { getNiceTickValues } from 'recharts-scale';
import { getTailwindColor } from '../../utils/colorUtils';
import ChartsLegend from './ChartsLegend';
import ChartsTooltip from './ChartsTooltip';

const DEFAULT_REF_AREA = { x1: "", x2: "" };
const amber900Color = getTailwindColor('text-amber-900');
const useGesture = createUseGesture([pinchAction, dragAction]);

function generateLine(y, category, field) {
  return (
    <Area connectNulls
      key={y[category]['fields'][field]['key']}
      type='monotone'
      dataKey={y[category]['fields'][field]['key']}
      stroke='none'
      fill={y[category]['fields'][field]['color']}
      fillOpacity={1}
      stackId='all'
      isAnimationActive={false} />
  );
}

export default function TimescaleLine({ data, x, y, date, onDateChange, t }) {
  const startOfDay = useMemo(() => { return moment(date).startOf('day'); }, [date]);
  const endOfDay = useMemo(() => { return moment(startOfDay).add(1, 'day'); }, [startOfDay]);
  const defaultZoomArea = useMemo(() => {
    return {
      x1: startOfDay.unix() * 1000,
      x2: endOfDay.unix() * 1000
    };
  }, [startOfDay, endOfDay]);

  const [zoomArea, setZoomArea] = useState(defaultZoomArea);
  const [refArea, setRefArea] = useState(DEFAULT_REF_AREA);
  const [isZooming, setIsZooming] = useState(false);
  const [isZoomingByGesture, setIsZoomingByGesture] = useState(false);
  const [isZoomed, setIsZoomed] = useState(false);
  const isMobile = useIsMobile();

  const timeDomain = useMemo(() => { return d3.scaleTime().domain([startOfDay, endOfDay]); }, [startOfDay, endOfDay]);
  const defaultTicks = useMemo(() => { return timeDomain.ticks(d3.timeHour); }, [timeDomain]);

  const transformedData = useMemo(() => fill(transform(data, x, y), date), [data, x, y, date]);
  const [ticks, setTicks] = useState(defaultTicks);
  const [yTicks, setYTicks] = useState([]);
  const [maxYTick, setMaxYTick] = useState(0);
  const [debugInfo, setDebugInfo] = useState('');


  const getAxisYDomain = useCallback((from, to) => {
    return transformedData
      .filter(d => d[x] >= from && d[x] <= to)
      .reduce((top, d) => {
        let sumD = Object.keys(d)
          .filter(key => key !== x)
          .reduce((acc, key) => acc + d[key], 0);
        return top < sumD ? sumD : top;
      }, 0);
  }, [transformedData, x, y]);

  const updateYTicks = useCallback((x1, x2) => {
    let newYTicks = getNiceTickValues([0, getAxisYDomain(x1, x2)], 8);
    setYTicks(newYTicks);
    setMaxYTick(newYTicks[newYTicks.length - 1]);
  }, [getAxisYDomain]);

  const handleZoomOut = useCallback(() => {
    setIsZoomed(false);
    setZoomArea(defaultZoomArea);
    setRefArea(DEFAULT_REF_AREA);
    setTicks(defaultTicks);
    updateYTicks(defaultZoomArea.x1, defaultZoomArea.x2);
  }, [defaultZoomArea, updateYTicks, setIsZoomed, setZoomArea, defaultTicks, setTicks]);

  useEffect(() => {
    const handler = (e) => e.preventDefault();
    document.addEventListener('gesturestart', handler);
    document.addEventListener('gesturechange', handler);
    document.addEventListener('gestureend', handler);
    return () => {
      document.removeEventListener('gesturestart', handler);
      document.removeEventListener('gesturechange', handler);
      document.removeEventListener('gestureend', handler);
    };
  }, []);

  const ref = useRef(null);
  const chartRef = useRef(null);

  useGesture(
    {
      onDrag: ({ pinching, cancel, movement: [x], memo, first, swipe: [swipeX], active }) => {
        if (pinching) return cancel();

        setIsZoomingByGesture(active);
        chartRef.current.setState({ isTooltipActive: false });

        if (!isZoomed && swipeX != 0 && onDateChange) {
          const newDate = (swipeX < 0) ? moment(date).add(1, 'day').toDate() : moment(date).subtract(1, 'day').toDate();
          onDateChange(newDate);
          return cancel();
        }

        if (!isZoomed) return memo;
        const rect = ref.current.getBoundingClientRect();
        if (first) {
          memo = [zoomArea.x1, zoomArea.x2];
        }
        const [zoomAreaX1, zoomAreaX2] = memo;
        const ratio = x / (rect.width - 50);
        const xTime = ratio * (zoomAreaX2 - zoomAreaX1);
        let newRefArea = { x1: zoomAreaX1 - xTime, x2: zoomAreaX2 - xTime };
        if (newRefArea.x1 < defaultZoomArea.x1) {
          newRefArea = {
            x1: defaultZoomArea.x1,
            x2: defaultZoomArea.x1 + (zoomAreaX2 - zoomAreaX1)
          };
        }
        if (newRefArea.x2 > defaultZoomArea.x2) {
          newRefArea = {
            x1: defaultZoomArea.x2 - (zoomAreaX2 - zoomAreaX1),
            x2: defaultZoomArea.x2
          };
        }
        setRefArea(newRefArea);
        return memo;
      },
      onPinch: ({ origin: [ox], movement: [scale], memo, first, active }) => {
        const rect = ref.current.getBoundingClientRect();
        if (first) {
          memo = [zoomArea.x1, zoomArea.x2, ox];
        }
        setIsZoomingByGesture(active);
        chartRef.current.setState({ isTooltipActive: false });
        const [zoomAreaX1, zoomAreaX2, initialOx] = memo;
        const ratio = (initialOx - rect.x - 40) / (rect.width - 50);
        const oxTime = zoomAreaX1 + ratio * (zoomAreaX2 - zoomAreaX1);
        let newRefArea = { x1: oxTime + (zoomAreaX1 - oxTime) / scale, x2: oxTime + (zoomAreaX2 - oxTime) / scale };
        const MIN_DISTANCE = 3 * 60 * 1000;
        if (Math.abs(newRefArea.x2 - newRefArea.x1) < 3 * 60 * 1000) {
          const midPoint = (newRefArea.x1 + newRefArea.x2) / 2;
          newRefArea = { x1: midPoint - MIN_DISTANCE / 2, x2: midPoint + MIN_DISTANCE / 2 };
        }
        if (newRefArea.x1 < defaultZoomArea.x1 || newRefArea.x2 > defaultZoomArea.x2) {
          newRefArea = defaultZoomArea;
        }
        setRefArea(newRefArea);
        return memo;
      }
    },
    {
      target: ref,
      pinch: { scaleBounds: { min: 1, max: 480 } }
    }
  );

  const showZoomBox = isZooming;

  function handleMouseDown(e) {
    if (isMobile) return;
    setIsZooming(true);
    setRefArea({ x1: e.activeLabel, x2: "" });
  }

  function handleMouseMove(e) {
    if (isZooming) {
      setRefArea((prev) => ({ ...prev, x2: e.activeLabel }));
    }
  }

  function handleMouseUp(e) {
    setIsZooming(false);
  }

  useEffect(() => {
    if (isZooming) return;
    let { x1, x2 } = refArea;
    if (x1 === defaultZoomArea.x1 && x2 === defaultZoomArea.x2) {
      handleZoomOut();
      return;
    }
    if (x1 === null || x2 === null || x1 === x2 || x2 === "") {
      return;
    }
    if (x1 > x2) [x1, x2] = [x2, x1];
    setZoomArea({ x1: x1, x2: x2 });
    let newTimeDomain = d3.scaleTime().domain([x1, x2]);
    setIsZoomed(true);
    setTicks(newTimeDomain.ticks());
  }, [isZooming, refArea, defaultZoomArea.x1, defaultZoomArea.x2, handleZoomOut]);

  useEffect(() => {
    updateYTicks(zoomArea.x1, zoomArea.x2);
  }, [transformedData, updateYTicks, zoomArea.x1, zoomArea.x2]);

  return (
    <div ref={ref} className='relative flex flex-col w-full h-full z-10 select-none font-sans relative touch-none items-center'>
      {(isZoomed && !isMobile) && <button type="button" className='top-0 absolute z-50 right-auto font-normal justify-center rounded-md border px-3 py-1 text-sm text-amber-900 shadow-sm border-amber-900 bg-white' onClick={handleZoomOut}>
        {t('MemberDashboard:reset_zoom')}
      </button>}
      <div className='absolute z-50 left-0 bg-white text-black text-xsm text-left'>{debugInfo}</div>
      <ResponsiveContainer width={'100%'} height={'100%'} >

        <AreaChart
          ref={chartRef}
          data={transformedData}
          margin={{ top: 0, right: 10, bottom: 10, left: 0 }}
          onMouseMove={e => handleMouseMove(e)}
          onMouseUp={e => handleMouseUp(e)}
          onMouseDown={e => handleMouseDown(e)}
        >
          {Object.keys(y).reverse().map(category => Object.keys(y[category]['fields']).map(field => generateLine(y, category, field)))}
          <Legend content={<ChartsLegend y={y} />} />
          {!isZoomingByGesture && <Tooltip
            content={<ChartsTooltip y={y} />}
            labelFormatter={(unixTime) => moment(unixTime).format('HH:mm')}
            position={isMobile ? { x: 50, y: 10 } : { y: 0 }}
          />}
          <CartesianGrid
            strokeDasharray='3 3'
            opacity={0.1}
            stroke={amber900Color}
          />
          <XAxis
            allowDataOverflow={true}
            dataKey={x}
            angle={90}
            textAnchor='begin'
            domain={[zoomArea.x1, zoomArea.x2]}
            scale='time'
            type='number'
            tick={{ fontSize: isMobile ? 8 : 10, fill: amber900Color }}
            tickLine={{ stroke: amber900Color }}
            ticks={ticks}
            tickFormatter={(unix) => moment(unix).format('HH:mm')}
            axisLine={{ stroke: amber900Color, strokeWidth: 1, opacity: 0.2 }}
          />

          {showZoomBox && (
            <ReferenceArea
              x1={refArea?.x1}
              x2={refArea?.x2}
            />
          )}
          <YAxis
            type='number'
            label={{ fontSize: isMobile ? 8 : 10, value: 'W', angle: -90, position: 'insideTopLeft', offset: 25, style: { fill: amber900Color } }}
            tick={{ fontSize: isMobile ? 8 : 10, fill: amber900Color }}
            tickLine={{ stroke: amber900Color }}
            axisLine={{ stroke: amber900Color, strokeWidth: 1, opacity: 0.2 }}
            tickCount={10}
            width={40}
            allowDataOverflow={true}
            domain={[0, maxYTick ? maxYTick : 5000]}
            ticks={yTicks}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div >
  );
}

function transform(data, x, y) {
  if (data.length === 0) return [];
  let updatedData = data.map((item) => {
    let result = { [x]: moment(item[x]).unix() * 1000 };
    Object.keys(y).forEach(category => {
      Object.keys(y[category]['fields']).forEach(field => {
        result[y[category]['fields'][field]['key']] = item[field];
      });
    });
    return result;
  });
  return updatedData;
}

function fill(data, date) {
  if (data.length === 0) {
    data = [{ time: getStartOfDayEpochMillis(date) }];
  }
  let [lastItem] = data.slice(-1);
  let time = lastItem.time;
  let lastItemTime = time;
  let step = 3 * 60 * 1000;
  let endOfDayMillis = moment(date).startOf('day').add(1, 'day').unix() * 1000;
  let fill = Array.from(
    { length: (endOfDayMillis - lastItemTime) / step },
    (_, index) => ({ time: lastItemTime + (index + 1) * step })
  );
  return data.concat(fill);
}

