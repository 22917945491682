import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import Connect from "./Connect";
import { useContext, useEffect, useState } from "react";
import { colors } from "../../utils/colors";
import tasmota_list_wifis from "./images/tasmota_list_wifis.png";
import { MemberContext, SetMemberContext } from "../../member-context";
import CommunitySelector from "../../components/CommunitySelector";
import MemberSelector from "../../components/MemberSelector";
import { CommunityContext, SetCommunityContext } from "../../community-context";
import { CheckIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import useFetch from "../../hooks/useFetch";

// TODO: picture smartmeter P1 port

async function retryAsyncFunction(fn, options) {
  const { retryIf, retries, waitTime } = { retryIf: () => false, retries: 1, waitTime: 1000, ...options };
  console.log(`retrying async function ${retries} times with wait time of ${waitTime}ms`);
  // Define the attempt counter.
  let attempts = 0;

  // Define a function that attempts to execute the promise-returning function.
  function attempt() {
    attempts++;
    console.log(`attempt ${attempts}/${retries}`);
    return new Promise((resolve, reject) => {
      fn()
        .then(result => {
          if (retryIf(result)) {
            // Check if the result should trigger a retry
            if (attempts < retries) {
              console.log(`Attempt ${attempts} succeeded but did not meet the condition, retrying in ${waitTime} ms...`);
              setTimeout(() => {
                attempt().then(resolve).catch(reject);
              }, waitTime);
            } else {
              // Resolves the promise when max attempts are reached,
              // even if retry condition is met, to avoid infinite loops.
              console.log('Max attempts reached, but last result still meets the retry condition.');
              resolve(result);
            }
          } else {
            // If the result does not meet the retry condition, resolve the promise with the result.
            console.log(result);
            resolve(result);
          }
        })
        .catch((error) => {
          console.log("ERROR !!!!");
          if (attempts < retries) {
            console.log(`Attempt ${attempts} failed, retrying in ${waitTime} ms...`);
            setTimeout(() => {
              attempt().then(resolve).catch(reject);
            }, waitTime);
          } else {
            reject(error);
          }
        });
    });
  }

  // Start the first attempt.
  return attempt();
}

export default function DeviceActivation() {

  const navigate = useNavigate();

  const ActivationStatuses = {
    UNKNOWN: 'UNKNOWN',
    ACTIVATING: 'ACTIVATING',
    ACTIVATED: 'ACTIVATED',
    ERROR: 'ERROR'
  };

  const { user, loading: userLoading, logout } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const member = useContext(MemberContext);
  const community = useContext(CommunityContext);
  const setMember = useContext(SetMemberContext);
  const setCommunity = useContext(SetCommunityContext);
  const deviceToken = searchParams.get("deviceToken");
  const deviceHostname = searchParams.get("deviceHostname");

  const [currentStepIndex, setCurrentStepIndex] = useState(1);
  const [steps, setSteps] = useState([]);

  const [currentStep, setCurrentStep] = useState();
  const [activationStatus, setActivationStatus] = useState(ActivationStatuses.UNKNOWN);

  useEffect(() => {
    const initialStepIndex = searchParams.get("step") ? parseInt(searchParams.get("step")) : 1;
    if (currentStepIndex !== initialStepIndex) setCurrentStepIndex(initialStepIndex);
  }, [searchParams, currentStepIndex]);

  useEffect(() => {
    const newCurrentStep = steps[currentStepIndex - 1];
    setCurrentStep(newCurrentStep);
  }, [steps, currentStepIndex]);

  // TODO: use handleFetch
  async function activateDevice() {
    if (!member) {
      console.log("unable to activate device - no member selected");
      return;
    }
    if (activationStatus === ActivationStatuses.ACTIVATED) {
      console.log("alreading activated");
      return;
    }
    if (activationStatus === ActivationStatuses.ACTIVATING) {
      console.log("alreading activating");
      return;
    }
    console.log("activating device for member  " + JSON.stringify(member));
    setActivationStatus(ActivationStatuses.ACTIVATING);
    let response;
    try {
      response = await retryAsyncFunction(() => fetch(`/api/members/${member.id}/devices/activate?` + new URLSearchParams({ deviceToken: deviceToken }), {
        method: "POST"
      }), { retryIf: (result) => !result?.ok, retries: 2, waitTime: 5000 });
    } catch (error) {
      console.log("Error while activating the device", error);
      setActivationStatus(ActivationStatuses.ERROR);
    }
    if (response.status === 401) {
      logout();
      return;
    }
    if (response?.ok) {
      setActivationStatus(ActivationStatuses.ACTIVATED);
      return response.json();
    }
    else {
      console.log(`Error while activating the device: ${response?.status} - ${response?.statusText}`);
      setActivationStatus(ActivationStatuses.ERROR);
    }
  }

  async function oldActivateDevice() {
    if (!member) {
      console.log("unable to activate device - no member selected");
      return;
    }
    if (activationStatus === ActivationStatuses.ACTIVATED) {
      console.log("alreading activated");
      return;
    }
    if (activationStatus === ActivationStatuses.ACTIVATING) {
      console.log("alreading activating");
      return;
    }
    console.log("activating device for member  " + JSON.stringify(member));
    setActivationStatus(ActivationStatuses.ACTIVATING);
    let response;
    try {
      response = await retryAsyncFunction(() => fetch(`/api/members/${member.id}/devices/activate?` + new URLSearchParams({ deviceToken: deviceToken }), {
        method: "POST"
      }), { retryIf: (result) => !result?.ok, retries: 1, waitTime: 5000 });
    } catch (error) {
      console.log("Error while activating the device", error);
      setActivationStatus(ActivationStatuses.ERROR);
    }
    if (response.status === 401) {
      logout();
      return;
    }
    if (response?.ok) {
      setActivationStatus(ActivationStatuses.ACTIVATED);
      return response.json();
    }
    else {
      console.log(`Error while activating the device: ${response?.status} - ${response?.statusText}`);
      setActivationStatus(ActivationStatuses.ERROR);
    }
  }

  useEffect(() => {
    if (!userLoading && user) {
      setSteps([
        {
          title: "Introduction",
          content:
            <div className="space-y-5">
              <p>Congratulations {user.fullName}!</p>
              <p>You have acquired a new Amber Energy device.</p>
              <p>We will guide you in the (short) process of activating your device.</p>
              <p>Click on the 'Next' button to start the activation process.</p>
            </div>
        },
        {
          title: "Requirements",
          content:
            <div className="space-y-5">
              <p>You will need some items to activate your devices.</p>
              <p>Gather:</p>
              <ul className="ml-10 list-disc">
                <li>Your Amber Energy device</li>
                <li>Your smartphone</li>
                <li>Your Wifi settings (SSID and password)</li>
              </ul>
              <p>Click on the 'Next' button at the bottom of this page when you are ready.</p>
            </div>
        },
        {
          title: "Go to your Smartmeter",
          content:
            <div className="space-y-5">
              <p>Go near to your smarmeter.</p>
              <p>Click on the 'Next' button at the bottom of this page when you are ready.</p>
            </div>
        },
        {
          title: "Plug the Device",
          content:
            <div className="space-y-5">
              <p>Plug the RJ12 connector in the P1 port of your smartmeter.</p>
              {/* TODO: add picture of device connected to P1 port */}
              <p>This will power up your device.</p>
              <p>Click on the 'Next' button at the bottom of this page when you are done.</p>
            </div>
        },
        {
          title: "Connect to the Device Wifi",
          content:
            <div className="space-y-5">
              <p>With your smartphone, connect to the Amber Energy device Wifi network.</p>
              <p>Select the network starting with 'amber-energy-' in the list of Wifi networks available.</p>
              <p>Click on the 'Next' button at the bottom of this page when you are ready.</p>
            </div>
          // TODO: add screenshot of list of Wifi networks
        },
        {
          title: "Go to the Wifi Configuration Screen",
          content:
            <div className="space-y-5">
              <img alt="list of Wifi networks" className="float-right shadow-xl border-2 sm:border-4 border-yellow rounded-xl w-32 sm:w-36 h-auto max-w-xs mx-2" src={tasmota_list_wifis}></img>
              <p>With your smartphone, turn off your mobile data</p>
              <p>To continue the setup while keeping this guide accessible, open the link below in a new browser tab:</p>
              <a
                href={deviceHostname ? `http://${deviceHostname}.local` : "#"}
                className="text-blue-500 underline"
                aria-label={`Go to the device Wifi configuration page for ${deviceHostname}`}
              >
                Configure Device Wifi
              </a>
              <p>This will take you to the Device Wifi configuration page (see picture).</p>
              <p>Click on the 'Next' button at the bottom of this page when you are done.</p>
            </div>
        },
        {
          title: "Connect the Device to Your Wifi",
          content:
            <div className="space-y-5">
              <img alt="list of Wifi networks" className="float-right shadow-xl border-2 sm:border-4 border-yellow rounded-xl w-32 sm:w-36 h-auto max-w-xs mx-2" src={tasmota_list_wifis}></img>
              <p>Select your Wifi network in the list on the top of the page.</p>
              <p>Enter your password in the 'WiFi Password' field.</p>
              <p>Click on the 'Save' Button.</p>
              <p>Your Amber Energy device will connect to your Wifi.</p>
              <p>Click on the 'Next' button at the bottom of this page when you are done.</p>
            </div>,
        },
        {
          title: "Activate Your Device",
          content:
            <div className="space-y-5">
              <p>Clik on the Activate button to start the activation of your device.</p>
              <button
                disabled={activationStatus === ActivationStatuses.ACTIVATING || activationStatus === ActivationStatuses.ACTIVATED}
                className="w-32 h-12 rounded-lg font-bold bg-custom-yellow sm:hover:bg-custom-dark-yellow rounded disabled:bg-gray-300 disabled:sm:hover:bg-gray-300 disabled:text-gray-700 text-black"
                onClick={() => activateDevice()}>
                <div className="relative flex items-center justify-center">Activate</div>
              </button>
              {(activationStatus === ActivationStatuses.ACTIVATING) &&
                <div>
                  <svg aria-hidden="true" class="inline w-5 h-5 mr-2 text-white animate-spin fill-custom-yellow" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                  </svg>
                  <span>Activating...</span>
                </div>
              }
              {activationStatus === ActivationStatuses.ERROR &&
                <div className="text-custom-orange">
                  <ExclamationTriangleIcon className="w-4 h-4 mr-2 inline" />
                  <span>Error while activating device: this is the error message. Please retry.</span>
                </div>
              }
              {activationStatus === ActivationStatuses.ACTIVATED &&
                <div className="text-custom-green">
                  <CheckIcon className="w-4 h-4 mr-2 inline" />
                  <span>Your device is now ACTIVE.</span>
                </div>
              }
              <p>Click on the 'Next' button at the bottom of this page when you are done.</p>
            </div>,
          buttonDisabled: activationStatus !== ActivationStatuses.ACTIVATED,
        },
        {
          title: "Summary",
          content:
            <div className="space-y-5">
              <p>Your device is now sucessfuly activated.</p>
              <p>You will see data coming in your <Link className="text-custom-green" to="/members">Dasboard</Link>.</p>
            </div>,
          buttonTitle: "Go to dashboard",
          onNext: function () {
            navigate("/members/");
          }
        },
      ]);
    }
  }, [user, userLoading, member, activationStatus]);

  function handleSubmit() {
    if (currentStep.onNext) {
      currentStep.onNext();
    }
  }

  function handleGoToNextStep() {
    if (currentStepIndex >= steps.length) return;
    if (currentStep.onNext) {
      currentStep.onNext();
    }
    searchParams.set("step", "" + (currentStepIndex + 1));
    setSearchParams(searchParams);
  }

  function handleGoToPreviousStep() {
    if (currentStepIndex <= 1) return;
    searchParams.set("step", "" + (currentStepIndex - 1));
    setSearchParams(searchParams);
  }

  return <div className="h-full">
    {(user && !userLoading) && (
      <>
        {!community && (
          <div className="flex justify-center my-8 py-3">
            <CommunitySelector setCommunity={setCommunity} />
          </div>
        )}
        {community && !member && (
          <div className="flex justify-center my-8 py-3">
            <MemberSelector setMember={setMember} community={community} />
          </div>
        )}
      </>
    )}

    <Connect
      isOpen={!user && !userLoading}
      title="Activate my device"
      description="In order to activate your device you must first authenticate" />

    {steps &&
      <>
        {currentStep && currentStepIndex && <div className="flex flex-col w-full h-full">
          <div>
            <div className="flex flex-row m-4">
              <div
                className="w-16 h-16 rounded-full items-center flex flex-col"
                style={{ backgroundImage: "conic-gradient(" + colors.green + " " + (currentStepIndex / steps.length) + "turn, #333 0 360deg)" }}
              >
                <div className="w-[3.2rem] h-[3.2rem] bg-white rounded-full m-2 text-center flex flex-col"><span className="m-auto">{currentStepIndex} of {steps.length}</span></div>
              </div>
              <div className="ml-10 sm:ml-20 mt-2">
                <h1 className="text-xl text-left font-bold text-gray-700">{currentStep.title}</h1>
                {(currentStepIndex < steps.length) && <h2 className="text-sm text-left text-gray-600">Next: {steps[currentStepIndex].title}</h2>}
              </div>
            </div>
          </div>
          <div className="p-6 bg-gray-700 text-white">
            <div className=" rounded-lg p-2 shadow-sm">{currentStep.content}</div>
          </div>
          <div className="h-20 gap-2 flex flex-row items-center px-6 bg-white">
            {(currentStepIndex > 1) &&
              <button
                className="w-32 h-12 rounded-lg font-bold bg-custom-yellow sm:hover:bg-custom-dark-yellow rounded disabled:bg-gray-300 disabled:sm:hover:bg-gray-300 disabled:text-gray-700"
                onClick={() => handleGoToPreviousStep()}>
                <div className="relative flex items-center justify-center">
                  <ChevronDoubleLeftIcon className="absolute left-2 h-5 w-5" />
                  Previous
                </div>
              </button>
            }
            <div className="grow"></div>
            {(currentStepIndex < steps.length) ?
              <button
                disabled={currentStep.buttonDisabled}
                className="w-32 h-12 rounded-lg font-bold bg-custom-yellow sm:hover:bg-custom-dark-yellow rounded disabled:bg-gray-300 disabled:sm:hover:bg-gray-300 disabled:text-gray-700"
                onClick={() => handleGoToNextStep()}>
                <div className="relative flex items-center justify-center">
                  {currentStep.buttonTitle ? currentStep.buttonTitle : "Next"}
                  <ChevronDoubleRightIcon className="absolute right-2 h-5 w-5" />
                </div>
              </button> :
              <button
                className="w-32 h-12 rounded-lg font-bold bg-custom-yellow sm:hover:bg-custom-dark-yellow rounded"
                onClick={() => handleSubmit()}>{currentStep.buttonTitle ? currentStep.buttonTitle : "Submit"}
              </button>
            }

          </div>
        </div>}
      </>
    }

  </div >;


};