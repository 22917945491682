import React from 'react';
import panels from '../images/panels.webp';
import smartmeter from '../images/smartmeter.webp';
import share from '../images/share.webp';
import share_community from '../images/share_community.webp';
import share_professional from '../images/share_professional.webp';

const detailedSectionsContent = [
    {
        key: "sell",
        title: "Selling Your Solar Surplus",
        image: panels,
        details: [
            "Turning your solar surplus into profit is simpler than you might think. Here’s how Amber facilitates the process:",
            [
                "Register your solar installation with Amber to become an energy producer.",
                "Monitor your production through our dashboard, and set preferences for surplus energy sales.",
                "Amber connects you with neighbors and local businesses interested in purchasing clean energy directly from you.",
                "Enjoy automated payments and detailed reports of your energy transactions.",
            ],
            "Start contributing to a greener community and earn from your renewable energy investment today.",
        ],
    },
    {
        key: "buy",
        title: "Purchasing Solar Surplus",
        image: share,
        details: [
            "Buying solar surplus from your neighbors not only reduces your carbon footprint but also supports your community. Here's how:",
            [
                "Sign up with Amber and express your interest in purchasing local solar energy.",
                "Set your energy preferences and budget through our user-friendly platform.",
                "Amber matches you with local solar producers, ensuring you get clean energy at competitive rates.",
                "Track your energy sources, consumption, and savings all in one place.",
            ],
            "Join the movement towards sustainable living by tapping into the solar potential around you.",
        ],
    },
    {
        key: "optimize",
        title: "Optimizing Energy Consumption",
        image: smartmeter,
        details: [
            "Amber helps you identify and adjust your energy habits for efficiency and savings. With our platform, you can:",
            [
                "Monitor your real-time energy usage with detailed analytics.",
                "Receive personalized recommendations for reducing consumption and increasing savings.",
                "Automate energy-saving adjustments with smart home integrations.",
                "Compare your usage trends over time and set goals for improvement.",
            ],
            "Let Amber guide you to smarter energy habits and a more sustainable lifestyle.",
        ],
    },
    {
        key: "community",
        title: "Maximizing Community Energy Sharing",
        image: share_community,
        details: [
            "Enhancing energy efficiency within a community starts with collaboration. Amber’s community-focused features enable:",
            [
                "Group energy plans that leverage collective bargaining for better rates.",
                "Shared insights and tips among community members for reducing consumption.",
                "Community solar projects that benefit all participants with clean, cost-effective energy.",
                "Tools for tracking and celebrating community achievements in sustainability.",
            ],
            "Empower your community with Amber and make a collective impact on the environment.",
        ],
    },
    {
        key: "partnership",
        title: "Empowering Clients with Amber",
        image: share_professional,
        details: [
            "For professionals in the solar and energy sector, Amber offers partnership opportunities to provide added value to your clients:",
            [
                "Integrate Amber’s platform to offer comprehensive energy monitoring and management solutions.",
                "Enable your clients to become part of a vibrant energy-sharing community.",
                "Provide insights and analytics to help clients optimize their energy consumption and production.",
                "Strengthen your service offerings with our cutting-edge technology and dedicated support.",
            ],
            "Partner with Amber to deliver exceptional value and service to your clients in the energy transition journey.",
        ],
    },
];

export default function ProfileDetails({ renderDetail, assignRef }) {

    return (
        <div id="profileDetail">
        {detailedSectionsContent.map(({ key, title, image, details }, index) => (
            <div
                key={key}
                ref={assignRef(key)}
                className={`p-6 transform transition-transform duration-500 ${
                    index % 2 === 0 ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'
                }`}
            >
                <div className="text-center mb-6">
                    <h3 className="text-2xl font-semibold mb-2">{title}</h3>
                    <img src={image} alt={title} className="w-32 h-32 mx-auto rounded-full mb-4" />
                </div>
                <div className="max-w-xl mx-auto">
                    {details.map((detail, detailIndex) => (
                        <div key={detailIndex} className="mb-4 text-left">
                            {renderDetail(detail)}
                        </div>
                    ))}
                </div>
            </div>
        ))}
    </div>
    );
}