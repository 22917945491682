import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

const settingsOptions = [
    { label: "Profile", path: "/settings/profile" },
    { label: "Security", path: "/settings/security" },
    { label: "Preferences", path: "/settings/preferences" },
    { label: "Users", path: "/settings/users" }, // Moved "Current Users" here
    { label: "Members", path: "/settings/members" },
];

export default function Settings() {
    const auth = useAuth();
    const [openSubOption, setOpenSubOption] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    // const getContentComponent = () => {
    //     const path = location.pathname;
    //     if (path.includes("/settings/users/users")) return <Users />;
    //     if (path.includes("/settings/users/add")) return <InvitationForm />;
    //     if (path.includes("/settings/users/invitations")) return <ViewInvitations />;
    //     if (path.includes("/settings/users/requests")) return <ViewRequests />;
    //     return null;
    // };

    useEffect(() => {
        // Optionally close suboptions when navigating away
        if (!location.pathname.includes("/settings/users")) {
            setOpenSubOption(null);
        }
    }, [location]);

    return (
        <>
            {auth.user && (
                <div className="flex flex-col lg:flex-row px-4 lg:px-8 py-4">
                    <div className="lg:w-full lg:max-w-sm mb-4 lg:mb-0">
                        <h2 className="text-xl md:text-2xl font-semibold text-amber-900 mb-4">Settings</h2>
                        <div className="shadow-md rounded-lg overflow-hidden border-amber-900">
                            {settingsOptions.map((option, index) => (
                                <div key={index} className="bg-white">
                                    <div
                                        className="flex items-center p-2 hover:bg-amber-100 cursor-pointer text-amber-900"
                                        onClick={() => navigate(option.path)}
                                    >
                                        <div className="h-4 w-4 mr-2" /> {/* Placeholder for alignment */}
                                        <span className="text-sm">{option.label}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex-grow p-4 lg:p-8">
                        <Outlet />
                    </div>
                </div>
            )}
        </>
    );
}
