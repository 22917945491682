import { useContext } from 'react';
import { MemberContext } from '../member-context';
import Selector from './Selector';

export default function MemberSelector({ setMember, community }) {
  const member = useContext(MemberContext);

  return (
    <Selector
      element={member}
      setElement={setMember}
      type='member'
      url={community ? '/api/communities/' + community.id + '/mymembers/' : null}
    />);
}
