import { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import useFetchInterval from '../../hooks/useFetchInterval';
import translationEN from './translations/en.json';
import translationFR from './translations/fr.json';
import translationNL from './translations/nl.json';
import useComponentTranslation from '../../hooks/useComponentTranslations';
import { useLocation } from 'react-router-dom';

const devices = [
  { label: 'hvac', power: 5000, icon: 'hvac' },
  { label: 'electric_car', power: 2400, icon: 'electric_car' },
  { label: 'dishwasher', power: 1000, icon: 'dishwasher_gen' },
  { label: 'oven', power: 1500, icon: 'oven_gen' },
  { label: 'lights', power: 50, icon: 'light_group' }
];

export default function DevicesListbox({ member }) {
  const { t } = useComponentTranslation('DevicesListbox', { 'en': translationEN, 'fr': translationFR, 'nl': translationNL });

  const [selectedDevices, setSelectedDevices] = useState([]);
  const [consumptions, setConsumptions] = useState([]);
  const [, setError] = useState([]);
  const location = useLocation();
  const shouldShow = /^\/members\/.+$/.test(location.pathname);

  let isLoading = true;

  ({ loading: isLoading } = useFetchInterval(
    '/api/members/' + member.id + '/consumptions/',
    setConsumptions,
    setError,
    isLoading,
    3 * 1000));

  useEffect(() => {
    let consumptionsByLabel = consumptions.reduce((group, consumption) => {
      const { label } = consumption;
      group[label] = group[label] ?? [];
      group[label].push(consumption);
      return group;
    }, {});

    let newSelectedDevices = [];
    Object.entries(consumptionsByLabel).forEach(entry => {
      const [label, consumptions] = entry;
      devices.filter(device => device.label === label).forEach(device => {
        device.consumption = consumptions[0];
        newSelectedDevices.push(device);
      });
    });
    setSelectedDevices(newSelectedDevices);

  }, [consumptions]);

  function onDevicesSelected(devices) {
    devices
      .filter(device => !selectedDevices.includes(device))
      .forEach(device => {
        fetch("/api/members/" + member.id + "/consumptions?" +
          new URLSearchParams({ power: device.power, label: device.label }), { method: "POST" })
          .then(res => res.json())
          .then(
            (result) => {
              device.consumption = result;
            },
            (error) => {
              setError(error);
            }
          );
      });
    selectedDevices
      .filter(selectedDevice => !devices.includes(selectedDevice))
      .forEach(selectedDevice => {
        fetch("/api/members/" + member.id + "/consumptions/" + selectedDevice.consumption.id, { method: "DELETE" })
          .then(
            (result) => {
            },
            (error) => {
              setError(error);
            }
          );
      });
    setSelectedDevices(devices);
  }

  return (
    <>
    {shouldShow && (
    <Listbox value={selectedDevices} onChange={onDevicesSelected} multiple as="div" className="relative inline-block text-left w-200">
      <Listbox.Button className="inline-flex w-12 md:w-auto justify-center rounded-md border bg-white px-5 py-2 text-sm font-medium text-amber-900 shadow-sm border-amber-900">
        <div className="-ml-3 h-4 w-4 material-symbols-outlined text-xl">electrical_services</div>
        <div className="hidden md:block ml-2">{t('DevicesListbox:consumptions')}</div>
        <ChevronUpDownIcon className="-mr-4 ml-0 h-5 w-5" aria-hidden="true" />
      </Listbox.Button>
      <Transition
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Listbox.Options className="bg-opacity-100 absolute right-0 z-10 mt-1 w-[30] origin-top-right rounded-md bg-white shadow-lg focus:outline-none border border-amber-900">
          {devices.map((device, deviceIdx) => (
            <Listbox.Option
              key={deviceIdx}
              className="relative text-sm cursor-default select-none py-2 pl-6 pr-4 ui-active:text-amber-600 ui-selected:text-amber-600"
              value={device}
            >
              {({ selected }) => (
                <>
                  <span className="flex flex-row align gap-2 align-middle">
                    <span className="material-symbols-outlined text-xl">{device.icon}</span>
                    <span className="pb-0 mt-1">{device.power}&nbsp;W</span>
                  </span>
                  {selected ? (
                    <span className="absolute inset-y-0 left-0 flex items-center pl-2 text-amber-600">
                      <CheckIcon className="h-4 w-4" aria-hidden="true" />
                    </span>
                  ) : null}
                </>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Transition>
    </Listbox >
    )}
    </>
  );
}