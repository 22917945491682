import { Menu } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

export default function LanguageSelector({ isBeginningOfWelcomePage }) {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const languages = i18n.languages;

  const textColorClass = isBeginningOfWelcomePage ? 'text-white' : 'text-amber-900';
  const textOpacityClass = isBeginningOfWelcomePage ? 'text-opacity-50' : '';
  const bgColorClass = isBeginningOfWelcomePage ? 'bg-gray-600 bg-opacity-40' : 'bg-amber-500';
  const borderColorClass = isBeginningOfWelcomePage ? 'border-white' : 'border-amber-900';
  const uiOpenTextColorClass = isBeginningOfWelcomePage ? 'ui-open:text-white' : 'ui-open:text-amber-700';
  const uiOpenBgColorClass = isBeginningOfWelcomePage ? 'ui-open:bg-gray-600 ui-open:bg-opacity-40' : 'bg-amber-500';
  
  const menuItemLayout = `ui-active:${textColorClass} ui-active:text-opacity-100 ${textColorClass} ${textOpacityClass} block w-10 px-2 py-1 text-left text-sm`;
  
  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <Menu.Button className={`ui-open:border-t ui-open:border-x ${uiOpenTextColorClass} ${uiOpenBgColorClass} ${textColorClass} ${textOpacityClass} ui-open:text-opacity-100 ui-not-open:border-none rounded-t-md inline-flex w-12 justify-center bg-none px-0 py-2 text-sm font-medium shadow-sm ${borderColorClass}`}>
            <span className={`-ml-3 h-4 w-4 ${textColorClass} ${textOpacityClass}`}>{currentLanguage.toUpperCase()}</span>
            <ChevronDownIcon className="-mr-4 ml-0 h-5 w-5" aria-hidden="true" />
          </Menu.Button>
  
          <Menu.Items className={`absolute z-10 w-12 origin-top-right rounded-b-md ${bgColorClass} shadow-lg focus:outline-none border-b border-x ${borderColorClass}`}>
            <div>
              {languages.filter(lang => lang != currentLanguage).map((lang, langIdx) => (
                <Menu.Item key={langIdx}>
                  <button className={menuItemLayout} onClick={() => i18n.changeLanguage(lang)}><span>{lang.toUpperCase()}</span></button>
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </>
      )}
    </Menu>
  );
};;