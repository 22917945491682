import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import useFetchInterval from '../../hooks/useFetchInterval';
import LastPowerStats from "../../components/LastPowerStats";
import { useErrors } from '../../hooks/useErrors';
import DataAccessStatuses from '../../components/DataAccessStatuses';

export default function LastPowers({ fields, onDataStatusChange, t }) {

  const [lastPowers, setLastPowers] = useState({});
  const [dataAccessStatus, setDataAccessStatus] = useState(DataAccessStatuses.LOADING);
  const routeParams = useParams();
  const memberId = routeParams.id;

  const errors = useErrors();

  useEffect(() => {
    onDataStatusChange(dataAccessStatus);
  }, [dataAccessStatus, onDataStatusChange]);

  useFetchInterval(
    '/api/members/' + memberId + '/powers/last',
    (powers) => {
      let transformedPowers = {
        ...powers,
        "available": (powers.produced - powers.selfConsumed) + (powers.producedByCommunity - powers.consumedFromCommunity),
        "availableFromMe": powers.produced - powers.selfConsumed,
        "availableFromCommunity": powers.producedByCommunity - powers.consumedFromCommunity
      };
      setLastPowers(transformedPowers);
      setDataAccessStatus(DataAccessStatuses.FRESH_DATA);
    },
    (error) => {
      errors.add(error);
      if (Object.keys(lastPowers).length !== 0) {
        setDataAccessStatus(DataAccessStatuses.ERROR_STALE_DATA);
      } else {
        setDataAccessStatus(DataAccessStatuses.ERROR_NO_DATA);
      }
    },
    dataAccessStatus === DataAccessStatuses.LOADING,
    5 * 1000);

  if (dataAccessStatus === DataAccessStatuses.LOADING) {
    return <div>{t('loading')}...</div>;
  } else {
    return (
      <>
        <LastPowerStats data={lastPowers} metadata={fields} dataAccessStatus={dataAccessStatus} t={t} />
        {/* <Gauge data={transformLast(lastPowers, fields)} title='Available power in community' /> */}
      </>
    );
  }
}
