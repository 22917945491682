import { useState, useEffect, useRef, useCallback } from 'react';
import { useAuth } from './useAuth';
import ERRORS from '../utils/errors';

async function handleFetchNew(url, options) {
  let data;
  let error;

  if (!url) return;

  try {
    const response = await fetch(url, {
      headers: { 'Content-Type': 'application/json', ...options.headers },
      ...options,
    });

    if (response.status === 504) {
      error = { status: response.status, statusText: response.statusText, response: null, message: ERRORS.TIMEOUT_ERROR };

    } else if (!response.ok) {
      error = { status: response.status, statusText: response.statusText, response: await response.json(), message: ERRORS.SERVER_ERROR + response.statusText };
    } else {
      data = await response.json();
    }
  } catch (e) {
    error = { status: 400, statusText: 'Network error', response: null, message: ERRORS.DEFAULT_FETCH_ERROR };
    console.log(error);
  }
  return { data, error };
}


export default function useFetch(url, fetchNow = true) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [error, setError] = useState();
  const fetchRef = useRef(() => { });
  const { logout } = useAuth();
  const cachedLogout = useCallback(() => logout(), []);
  //TODO: permettre des post + l'utiliser dans useFetchInterval

  useEffect(() => {
    fetchRef.current = async () => {
      setLoading(true);
      const { data: fetchData, error: fetchError } = await handleFetchNew(url, {});
      if (fetchError?.status === 401) cachedLogout();
      setData(fetchData);
      setError(fetchError);
      setLoading(false);

    };
    if (fetchNow) {
      fetchRef.current();
    }
  }, [url, fetchNow, cachedLogout]);

  return { loading, data, error, fetch: fetchRef.current };
}