import useFetch from '../hooks/useFetch';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../hooks/useAuth';
import { BoltIcon } from '@heroicons/react/24/outline';


export default function Demo() {

  const auth = useAuth();
  const navigate = useNavigate();

  const login = (email) => {
    auth.login(email).then(() => {
      navigate('/members/');
    });
  };

  const { data: communities, error } = useFetch('/public-api/communities/demo/');

  if (error == null && communities && communities.length > 1) {
    return (
      <div className="w-fit h-full mx-auto text-center flex mt-2 md:mt-16 text-amber-900 flex-wrap">
        {communities.map(community => (
          <div key={community.id} className="md:mx-10 my-1 w-96 border-2 border-amber-900 rounded-md">
            <div className="uppercase font-bold text-lg mb-2">{community.name}</div>
            {community.members.map(member => (
              <div key={member.id} className="flex flex-col p-1">
                <div className="justify-center inline-flex gap-1 font-semibold uppercase text-xs mb-2">
                  {member.name}{member.isProducer && (<BoltIcon className="text-amber-600 fill-amber-200 h-4 w-4" />)}
                </div>
                <div className="flex flex-nowrap flex-row gap-2 mx-3 justify-center">
                  {member.users.map(user => (
                    <button key={user.id} className="font-semibold text-xs box-border border border-amber-900 h-20 w-20 rounded-md bg-amber-300" onClick={() => login(user.email)}>
                      {user.fullName}
                    </button>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>);
  }

};