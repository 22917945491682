import React, { useState } from 'react';
import { EllipsisVerticalIcon, PencilIcon, TrashIcon } from '@heroicons/react/20/solid';

export default function UserDropdown({ onEdit, onDelete, isCurrentUser }) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="relative inline-block text-left">
            <button onClick={() => setIsOpen(!isOpen)} className="p-2 text-gray-500 hover:text-gray-700">
                <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {isOpen && (
                <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                    <div className="py-1">
                        <button
                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            onClick={onEdit}
                        >
                            <PencilIcon className="h-5 w-5 mr-2 inline" aria-hidden="true" />Edit
                        </button>
                        {!isCurrentUser && (<button
                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            onClick={onDelete}
                        >
                            <TrashIcon className="h-5 w-5 mr-2 inline" aria-hidden="true" />Delete
                        </button>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};