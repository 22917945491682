import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import DevicesListbox from "../../../components/DevicesListBox/DevicesListBox";
import UserBadge from "../../../components/UserBadge/UserBadge";
import { useAuth } from "../../../hooks/useAuth";
import { MemberContext } from "../../../member-context";
import LanguageSelector from "../../../components/LanguageSelector";
import translationEN from './translations/en.json';
import translationFR from './translations/fr.json';
import translationNL from './translations/nl.json';
import useComponentTranslation from '../../../hooks/useComponentTranslations';
import './Navbar.css';

export default function Navbar() {
  const { t } = useComponentTranslation('Navbar', { 'en': translationEN, 'fr': translationFR, 'nl': translationNL });

  const auth = useAuth();
  const member = useContext(MemberContext);
  const location = useLocation();
  const [isWelcomePage, setIsWelcomePage] = useState(location.pathname === '/welcome');

  useEffect(() => {
    setIsWelcomePage(location.pathname === '/welcome');
  }, [location.pathname]);

  const [isBeginningOfWelcomePage, setIsBeginningOfWelcomePage] = useState(false);

  useEffect(() => {
    if (!isWelcomePage) {
      setIsBeginningOfWelcomePage(false);
      return;
    }

    const targetElement = document.getElementById('invitation');
    const navbarElement = document.getElementById('navbar');

    const handleScroll = () => {
      if (!targetElement || !navbarElement) return;

      const navbarElementPosition = navbarElement.getBoundingClientRect().bottom;
      const targetPosition = targetElement.getBoundingClientRect().bottom;

      setIsBeginningOfWelcomePage(targetPosition > navbarElementPosition);
    };

    handleScroll();

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isWelcomePage]);

  const textColor = isBeginningOfWelcomePage ? 'text-white text-opacity-50' : 'text-amber-900';
  const borderColor = isBeginningOfWelcomePage ? 'border-white border-opacity-20' : 'border-amber-900';
  const backgroundColor = isBeginningOfWelcomePage ? 'bg-transparent' : 'bg-amber-500';
  const hoverTextColor = isBeginningOfWelcomePage ? 'hover:text-white' : 'hover:text-amber-700';

  //TODO: allow using this "transition-all duration-300 ease-in-out" with synchronization between navbar and its children
  const navbarClass = `z-[100] flex fixed top-0 inset-x-0 h-16 items-center ${backgroundColor} ${textColor} ${borderColor} border-b`;

  return (
    <div id="navbar" className={navbarClass}>
      <div className="w-full relative mx-auto px-6">
        <div className="flex flex-row items-center -mx-6">
          <div className="w-1/4 md:w-1/6 pl-6 pr-6 lg:pr-8 uppercase font-extrabold text-lg md:text-2xl">
            <div className={`items-center ${hoverTextColor}`}><Link to={`/`}>Amber</Link></div>
          </div>
          {auth.user ? (
            <div className={`grow text-center sm:text-left lg:w-1/4 xl:w-1/5 ${hoverTextColor}`}>
              <Link to={'/members'}>{t('Navbar:member_dashboard')}</Link>
            </div>
          ) : (
            <div className={`grow text-center sm:text-left lg:w-1/4 xl:w-1/5 ${hoverTextColor}`}>
            </div>
          )
          }
          {!auth.user &&
            <div className="grow lg:w-1/4 xl:w-1/5 pl-2 pr-6 lg:pr-8 font-semibold">
              <div className={`items-center ${hoverTextColor}`}><Link to={'/demo/'}>Demo</Link></div>
            </div>
          }
          {(auth && auth.user && auth.user.isDemo && member) &&
            <div className="grow ml-auto mr-2 pl-0 pr-0">
              <div className="items-center"><DevicesListbox member={member} /></div>
            </div>
          }
          <div className="ml-auto mr-2 pl-0 pr-0">
            <div className="items-center"><LanguageSelector isBeginningOfWelcomePage={isBeginningOfWelcomePage} /></div>
          </div>
          <div className="ml-auto mr-2 pl-0 pr-0">
            <div className="items-center"><UserBadge isBeginningOfWelcomePage={isBeginningOfWelcomePage} /></div>
          </div>
        </div>
      </div>
    </div >
  );
};