// https://coolors.co/palette/f94144-f3722c-f8961e-f9844a-f9c74f-90be6d-43aa8b-4d908e-577590-277da1
const colors = {
  red: '#F94144',
  green: '#90BE6D',
  yellow: '#F9C74F',
  darkYellow: '#F8961E',
  darkGreen: '#43AA8B',
  orange: '#F8961E',
  blue: '#277DA1'
};

//TODO: check if we need to align with the other way to do exports (for module)
module.exports = {
  colors,
};