import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MemberContext } from '../../member-context';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { useErrors } from '../../hooks/useErrors';
import handleFetch from '../../utils/handleFetch';

export default function InviteForm({ existingUsers, onClose }) {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const navigate = useNavigate();
    const member = useContext(MemberContext);
    const errors = useErrors();
    const [loading, setLoading] = useState(false);
    const existingEmails = existingUsers.map(user => user.email.toLowerCase());

    const handleInvite = async (e) => {
        e.preventDefault();

        if (!email || !name) {
            errors.add(new Error("Please enter both name and email address."));
            return;
        }

        if (!email.endsWith('@gmail.com')) {
            errors.add(new Error("Only Gmail accounts are supported."));
            return;
        }

        if (existingEmails.includes(email.toLowerCase())) {
            errors.add(new Error("This user is already a member."));
            return;
        }
        try {
            await handleFetch(`/api/members/${member.id}/invites/`, {
                method: 'POST',
                body: JSON.stringify({ email, name, status: 'Invited', memberId: member?.id }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }, setLoading,
                (data) => {
                    if (data) {
                        console.log('Invite sent successfully', data);
                        navigate('/settings/users');
                        onClose();
                    }
                }, (error) => {
                    errors.add(error);
                });

        } catch (err) {
            console.error('Error in fetching:', err);
            errors.add(err);
        }
    };

    return (
        <div className="relative w-full px-2 py-6 border border-gray-200 rounded-lg">
            <button
                onClick={onClose}
                className="absolute top-0 right-0 text-gray-400 hover:text-gray-600 p-2 z-50"
                title="Close"
            >
                <XMarkIcon className="h-6 w-6" />
            </button>
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">{`Add user to ${member ? member.name : 'the household'}`} </h2>
            <form onSubmit={handleInvite} className="space-y-6">
                <div>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                        Name
                    </label>
                    <input
                        type="text"
                        name="name"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Full Name"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-amber-900 focus:border-amber-900"
                    />
                </div>
                <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Email Address
                    </label>
                    <input
                        type="email"
                        name="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="user@example.com"
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-amber-900 focus:border-amber-900"
                    />
                </div>

                <div>
                    <button
                        type="submit"
                        className="w-full inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-900"
                    >
                        Send Invite
                    </button>
                </div>
            </form>
        </div>
    );
}