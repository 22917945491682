import { useState, useEffect } from 'react';
import useFetchInterval from '../../hooks/useFetchInterval';
import { useErrors } from '../../hooks/useErrors';
import TimescaleBarChart from '../../components/TimescaleCharts/TimescaleBarChart';
import { getStartOfMonth, getEndOfMonth, getStartOfYear, getEndOfYear, formatDateForUrl } from '../../utils/dateUtils';
import DataAccessStatuses from "../../components/DataAccessStatuses";

function getParams(date, granularity) {
  switch (granularity) {
    case "year":
      return {
        from: getStartOfYear(date).format('YYYY-MM-DD'),
        to: getEndOfYear(date).subtract(1, 'millisecond').format('YYYY-MM-DD'),
        granularity: getNextGranularity(granularity)
      };
    default:
      return {
        from: getStartOfMonth(date).format('YYYY-MM-DD'),
        to: getEndOfMonth(date).subtract(1, 'millisecond').format('YYYY-MM-DD'),
        granularity: getNextGranularity(granularity)
      };
  }
}

function getNextGranularity(granularity) {
  switch (granularity) {
    case 'year':
      return 'month';
    case 'month':
      return 'day';
    case 'day':
    default:
      return null;
  }
}

export default function EnergyMemberDashboard({ fields, memberId, date, granularity, onDataStatusChange, t, onDateChange }) {
  const [energies, setEnergies] = useState([]);
  const [dataAccessStatus, setDataAccessStatus] = useState(DataAccessStatuses.LOADING);

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const errors = useErrors();

  const { from, to, granularity: granularityUrl } = getParams(date, granularity);

  useEffect(() => {
    onDataStatusChange(dataAccessStatus);
  }, [dataAccessStatus, onDataStatusChange]);

  useFetchInterval(
    '/api/members/' + memberId + '/energies/?' +
    new URLSearchParams({
      from: from,
      to: to,
      granularity: granularityUrl,
      timezone: timezone
    }),
    (result) => {
      let transformedResult = result.map(row => ({
        ...row,
        "available": (row.produced - row.selfConsumed) + (row.producedByCommunity - row.consumedFromCommunity),
        "availableFromMe": row.produced - row.selfConsumed,
        "availableFromCommunity": row.producedByCommunity - row.consumedFromCommunity
      }));
      setEnergies(transformedResult);
      setDataAccessStatus(DataAccessStatuses.FRESH_DATA);
    },
    (error) => {
      errors.add(error);
      if (Object.keys(energies).length !== 0) {
        setDataAccessStatus(DataAccessStatuses.ERROR_STALE_DATA);
      } else {
        setDataAccessStatus(DataAccessStatuses.ERROR_NO_DATA);
      }
    },
    dataAccessStatus === DataAccessStatuses.LOADING,
    60 * 1000, [date]);

  return (
    <div className="h-full flex flex-col text-center align-middle">
      {(dataAccessStatus === DataAccessStatuses.LOADING) ?
        <div className="text-amber-900">{t('loading')}...</div>
        :
        (dataAccessStatus !== DataAccessStatuses.ERROR_NO_DATA) && <TimescaleBarChart data={energies} x="startTime" y={fields} date={date} granularity={granularity} destinationBasePath={`/members/${memberId}/${getNextGranularity(granularity)}`} onDateChange={onDateChange} t={t} />
      }
      <div className={(dataAccessStatus === DataAccessStatuses.ERROR_NO_DATA ? "" : "hidden ") + "text-red-700"}>{t('MemberDashboard:unable_to_fetch')}</div>
      {(dataAccessStatus === DataAccessStatuses.ERROR_STALE_DATA) && <div className="text-center absolute text-xsm bottom-[3.5rem] mx-auto w-full text-red-700">{t('MemberDashboard:unable_to_update')}</div>}
    </div>
  );
}


