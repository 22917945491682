import { Link } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";
import { XCircleIcon } from '@heroicons/react/24/outline';
import translationEN from './translations/en.json';
import translationFR from './translations/fr.json';
import translationNL from './translations/nl.json';
import useComponentTranslation from '../../hooks/useComponentTranslations';

export default function Login() {
  const { t } = useComponentTranslation('Login', { 'en': translationEN, 'fr': translationFR, 'nl': translationNL });

  var [searchParams,] = useSearchParams();
  const redirectPath = '/members/';

  //TODO: improve switch case
  function errorMessage() {
    var message;
    var error = searchParams.get("error");
    if (!error) return;
    switch (error) {
      case "access_denied":
        message = "Access denied";
        break;
      case "server_error":
        message = "Server error";
        break;
      case "user_already_exists":
        message = "User already exists please login";
        break;
      default:
        message = "Error: " + error;
    }
    return (
      <div className="text-red-700">
        <div className="flex">
          <div className="py-1 mr-4">
            <XCircleIcon className="h-4 w-4 text-black-500" />
          </div>
          <span>{message}</span>
        </div>
      </div>
    );
  }

  return (
    <div className="relative py-16">
      <div className="relative container m-auto px-6 text-amber-900 md:px-12 xl:px-40">
        <div className="m-auto md:w-8/12 lg:w-6/12 xl:w-6/12">
          <div className="rounded-xl bg-white shadow-xl">
            <div className="p-6 sm:p-16">
              <div className="space-y-4">
                <h2 className="mb-8 text-2xl font-bold">{t('Login:sign_in')}</h2>
              </div>
              <div className="mt-16 grid space-y-4">
                <a className="group h-12 flex items-center px-6 border border-amber-900 rounded-full transition duration-300 hover:border-amber-600 focus:bg-blue-50 active:bg-blue-100"
                  href={'/login/google?redirect_uri=' + encodeURIComponent(window.location.protocol + "//" + window.location.host + redirectPath)}>
                  <div className="relative flex items-center space-x-14">
                    <img src="https://tailus.io/sources/blocks/social/preview/images/google.svg" className="absolute left-0 w-5" alt="google logo" />
                    <span className="block w-max font-semibold tracking-wide text-amber-900 text-sm transition duration-300 group-hover:text-amber-600 sm:text-base">{t('Login:google')}</span>
                  </div>
                </a>
                <Link to="/demo" className="group h-12 flex items-center px-6 border border-amber-900 rounded-full transition duration-300 hover:border-amber-600 focus:bg-blue-50 active:bg-blue-100">
                  <div className="relative flex items-center space-x-14">
                    <div className="absolute left-0 w-5" />
                    <span className="block w-max font-semibold tracking-wide text-amber-900 text-sm transition duration-300 group-hover:text-amber-600 sm:text-base">Demo</span>
                  </div>
                </Link>
                {errorMessage()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}