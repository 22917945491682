import { useParams, useNavigate } from "react-router-dom";
import LastPowers from './LastPowers.js';
import DailyMemberDashboard from './DailyMemberDashboard';
import EnergyMemberDashboard from './EnergyMemberDashboard.js';
import GranularitySelector from './GranularitySelector';
import { useState, useEffect } from "react";
import { Errors } from '../../hooks/useErrors';
import { colors } from '../../utils/colors';
import { getDateFromRouteParam, formatDateForUrl } from '../../utils/dateUtils';
import DataAccessStatuses from "../../components/DataAccessStatuses.js";
import CalendarSelector from "../../components/DaySelector/CalendarSelector.js";
import moment from "moment-timezone";

//TODO improve color definition so that it is defined at one place
//TODO store expected json schema somewhere so that backend and frontend can have a test validating that they respect the contract


export default function UserMemberDashboard({ t }) {
  const availableFromMe = { key: 'availableFomMe', label: t('MemberDashboard:me'), color: colors['yellow'], unit: 'W' };
  const availableFromCommunity = { key: 'availableFromCommunity', label: t('MemberDashboard:community'), color: colors['orange'], unit: 'W' };
  const consumedFromProvider = { key: 'consumedFromProvider', label: t('MemberDashboard:provider'), color: colors['red'], unit: 'W' };
  const selfConsumed = { key: 'selfConsumed', label: t('MemberDashboard:me'), color: colors['green'], unit: 'W' };
  const consumedFromCommunity = { key: 'consumedFromCommunity', label: t('MemberDashboard:community'), color: colors['darkGreen'], unit: 'W' };

  const fields = {
    available: {
      label: t('MemberDashboard:available_from'),
      fields: {
        availableFromMe: availableFromMe,
        availableFromCommunity: availableFromCommunity
      },
      timeField: 'inverterMinTime',
      color: colors['yellow'],
      unit: 'W'
    },
    consumed: {
      label: t('MemberDashboard:consumed_from'),
      fields: {
        selfConsumed: selfConsumed,
        consumedFromCommunity: consumedFromCommunity, consumedFromProvider: consumedFromProvider
      },
      timeField: 'smartmeterMinTime',
      color: colors['red'],
      unit: 'W'
    }
  };

  const routeParams = useParams();
  const memberId = routeParams.id;
  const [params, setParams] = useState({
    date: getDateFromRouteParam(routeParams.date, routeParams.granularity || 'day'),
    granularity: routeParams.granularity || 'day'
  });
  const [lastPowersAccessStatus, setLastPowersAccessStatus] = useState(DataAccessStatuses.LOADING);
  const [powersAccessStatus, setPowersAccessStatus] = useState(DataAccessStatuses.LOADING);
  const [energyAccessStatus, setEnergyAccessStatus] = useState(DataAccessStatuses.LOADING);
  const [completeAccessStatus, setCompleteAccessStatus] = useState(DataAccessStatuses.LOADING);

  const handleLastPowersAccessChange = (status) => {
    setLastPowersAccessStatus(status);
  };
  const handlePowersAccessChange = (status) => {
    setPowersAccessStatus(status);
  };
  const handleEnergyAccessChange = (status) => {
    setEnergyAccessStatus(status);
  };

  useEffect(() => {
    const newGranularity = routeParams.granularity || 'day';
    const newDate = getDateFromRouteParam(routeParams.date, newGranularity);

    if (params.date.getTime() !== newDate.getTime() || newGranularity !== params.granularity) {
      setParams({ date: newDate, granularity: newGranularity });
    }
  }, [routeParams]);

  useEffect(() => {
    let activeStatusForGraph = (() => {
      switch (params.granularity) {
        case 'day':
          return powersAccessStatus;
        case 'month':
        case 'year':
          return energyAccessStatus;
        default:
          return <Errors />;
      }
    })();

    if (lastPowersAccessStatus === DataAccessStatuses.LOADING && activeStatusForGraph === DataAccessStatuses.LOADING) {
      setCompleteAccessStatus(DataAccessStatuses.LOADING);
    } else if (lastPowersAccessStatus === DataAccessStatuses.ERROR_NO_DATA && activeStatusForGraph === DataAccessStatuses.ERROR_NO_DATA) {
      setCompleteAccessStatus(DataAccessStatuses.ERROR_NO_DATA);
    } else setCompleteAccessStatus(DataAccessStatuses.FRESH_DATA);

  }, [lastPowersAccessStatus, powersAccessStatus, energyAccessStatus, params.granularity]);

  const navigate = useNavigate();
  const handleGranularityChange = (newGranularity) => {
    navigate(`/members/${memberId}/${newGranularity}/${formatDateForUrl(params.date, newGranularity)}`);
  };
  const handleDateChange = (value) => {
    if (moment(value).isAfter(moment())) return;
    navigate(`/members/${memberId}/${params.granularity}/${formatDateForUrl(value, params.granularity)}`);
  };

  return (
    <>
      <div className={((completeAccessStatus === DataAccessStatuses.LOADING) ? "" : "hidden ") + "text-amber-900"}>{t('loading')}...</div>
      <div className={((completeAccessStatus === DataAccessStatuses.ERROR_NO_DATA) ? "" : "hidden ") + "text-red-700"}>{t('MemberDashboard:unable_to_fetch')}</div>
      <div className={((completeAccessStatus === DataAccessStatuses.LOADING || completeAccessStatus === DataAccessStatuses.ERROR_NO_DATA) ? "hidden" : "")
        + " overflow-auto fixed bottom-0 inset-0 top-16 w-full mr-4 space-y-2 flex flex-col"}>
        <LastPowers fields={fields} onDataStatusChange={handleLastPowersAccessChange} t={t} />
        <GranularitySelector onChange={handleGranularityChange} t={t} />
        <CalendarSelector onChange={handleDateChange} value={params.date} granularity={params.granularity} t={t} />
        {(() => {
          switch (params.granularity) {
            case 'day':
              return <DailyMemberDashboard key={params.date} date={params.date || new Date()} fields={fields} memberId={memberId} onDataStatusChange={handlePowersAccessChange} t={t} onDateChange={handleDateChange} />;
            case 'month':
            case 'year':
              return <EnergyMemberDashboard key={`${params.date}-${params.granularity}`} fields={fields} memberId={memberId} date={params.date} granularity={params.granularity} onDataStatusChange={handleEnergyAccessChange} t={t} onDateChange={handleDateChange} />;
            default:
              //TODO see if this can be improved
              return <Errors />;
          }
        })()}
      </div>
    </>
  );
}
