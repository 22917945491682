import { Link } from 'react-router-dom';
import { UserIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Menu } from '@headlessui/react';
import { useAuth } from '../../hooks/useAuth';

import translationEN from './translations/en.json';
import translationFR from './translations/fr.json';
import translationNL from './translations/nl.json';
import useComponentTranslation from '../../hooks/useComponentTranslations';

function getClasses(isBeginningOfWelcomePage) {
  const textColor = isBeginningOfWelcomePage ? 'text-white text-opacity-40' : 'text-amber-900';
  const bgColor = isBeginningOfWelcomePage ? 'bg-gray-600 bg-opacity-40' : 'bg-white';
  const borderColor = isBeginningOfWelcomePage ? 'border-white' : 'border-amber-900';
  const hoverTextColor = isBeginningOfWelcomePage ? 'text-white' : 'text-amber-600';

  return { textColor, bgColor, borderColor, hoverTextColor };
}

function badge(t, user, logout, classes) {
  const menuItemLayout = `ui-active:${classes.hoverTextColor} ui-active:text-opacity-100  ${classes.textColor} block w-full px-4 py-2 text-left text-sm`;
  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className={`inline-flex w-12 justify-center rounded-md border ${classes.bgColor} px-0 py-2 text-sm font-medium ${classes.textColor} shadow-sm ${classes.borderColor}`}>
        <UserIcon className={`-ml-3 h-4 w-4 ${classes.textColor}`} />
        <ChevronDownIcon className="-mr-4 ml-0 h-5 w-5" aria-hidden="true" />
      </Menu.Button>
      <Menu.Items className={`absolute right-0 z-10 mt-1 w-56 origin-top-right rounded-md ${classes.bgColor} shadow-lg focus:outline-none border ${classes.borderColor}`}>
        <div className="py-1">
          <Menu.Item>
            <span className={`${menuItemLayout} border-b ${classes.borderColor} ui-active:${classes.textColor}`}>{user.fullName}</span>
          </Menu.Item>
          {false && <Menu.Item>
            <Link to="/settings" className={menuItemLayout}>
              <span>{t('UserBadge:settings')}</span>
            </Link>
          </Menu.Item>}
          <Menu.Item>
            <button
              className={menuItemLayout}
              onClick={() => logout()}
            >
              <span>{t('UserBadge:logout')}</span>
            </button>
          </Menu.Item>
        </div>
      </Menu.Items>
    </Menu>
  );
}

function loginButton(t, classes) {
  return (
    <div className="relative inline-block text-left">
      <Link to={`/login`} className={`inline-flex w-full justify-center rounded-md border ${classes.borderColor} ${classes.bgColor} px-4 py-2 text-sm font-medium shadow-sm`}>
        <span className={`block font-semibold ${classes.textColor} text-sm transition duration-300 hover:${classes.hoverTextColor}`}>{t('UserBadge:login')}</span>
      </Link>
    </div>
  );
}

export default function UserBadge({ isBeginningOfWelcomePage }) {

  const auth = useAuth();
  const { t } = useComponentTranslation('UserBadge', { 'en': translationEN, 'fr': translationFR, 'nl': translationNL });

  const classes = getClasses(isBeginningOfWelcomePage);
  return (
    <div className={classes.textColor}>
      {auth && auth.user ? badge(t, auth.user, () => auth.logout(), classes) : loginButton(t, classes)}
    </div>
  );
}
