
export default function Footer() {
    return (
        <footer className="bg-gray-800 text-white py-8">
            <div className="container mx-auto px-4 flex flex-wrap justify-between items-start">
                <div className="mb-6 md:mb-0 w-full md:w-1/4">
                    <h4 className="text-lg font-bold">About Us</h4>
                    <p className="text-sm">Pioneering a sustainable future by connecting communities and promoting energy efficiency.</p>
                </div>
                <div className="mb-6 md:mb-0 w-full md:w-1/4">
                    <h4 className="text-lg font-bold">Quick Links</h4>
                    <ul>
                        <li><a href="/terms" className="block hover:text-gray-300 text-sm">Terms of Service</a></li>
                        <li><a href="/privacy" className="block hover:text-gray-300 text-sm">Privacy Policy</a></li>
                        <li><a href="/faq" className="block hover:text-gray-300 text-sm">FAQ</a></li>
                    </ul>
                </div>
                <div className="mb-6 md:mb-0 w-full md:w-1/4">
                    <h4 className="text-lg font-bold">Contact Us</h4>
                    <p className="text-sm">Have questions? Reach out to our support team.</p>
                    <p className="text-sm">support@example.com</p>
                </div>
                <div className="w-full md:w-1/4">
                    <h4 className="text-lg font-bold">Follow Us</h4>
                    <div className="social-icons">
                    </div>
                </div>
            </div>
        </footer>
    );
}