import React, { useState } from 'react';
import panels from '../images/panels.webp';
import smartmeter from '../images/smartmeter.webp';
import share from '../images/share.webp';
import share_community from '../images/share_community.webp';
import share_professional from '../images/share_professional.webp';
import Registration from '../Registration/Registration';

const questions = [
    {
        question: "Interested in turning your solar surplus into profit by selling it to your neighbors?",
        image: panels,
        buttonText: "How to sell my solar surplus?",
        key: "sell",
    },
    {
        question: "Want to benefit from the solar potential in your neighborhood?",
        image: share,
        buttonText: "How to buy other's solar surplus",
        key: "buy",
    },
    {
        question: "Looking for personalized ways to save on energy?",
        image: smartmeter,
        buttonText: "How can Amber help me adjust habits?",
        key: "optimize",
    },
    {
        question: "Already involved in an energy sharing initiative?",
        image: share_community,
        buttonText: "How to maximize community consumption?",
        key: "community",
    },
    {
        question: "Electrician, photovoltaic panel installer, condominium trustee?",
        image: share_professional,
        buttonText: "How to help my clients with Amber?",
        key: "partnership",
    },
];



export default function ProfileQuestions({ scrollToDetail, assignRef }) {
    const [isRegistrationOpen, setIsRegistrationOpen] = useState(false);

    const openRegistration = () => setIsRegistrationOpen(true);
    const closeRegistration = () => setIsRegistrationOpen(false);
    return (
        <div className="my-8">
            <section>
                <div key="profile-questions" ref={assignRef("profile-questions")} className="container mx-auto px-4">
                    <div className="flex flex-wrap justify-center gap-6">
                        {questions.map(({ question, image, key, buttonText }, index) => (
                            <div
                                key={index}
                                className="flex flex-col bg-white rounded-3xl overflow-hidden shadow-lg hover:shadow-xl duration-300 ease-in-out border border-green-800 lg:max-w-sm w-full" // Adjusted for rounder edges, colored border, and size
                            >
                                <img src={image} alt="" className="mx-auto w-24 h-24 rounded-lg mb-4" />
                                <div className="flex-1 p-4">
                                    <p className="text-xl text-gray-700 text-center">{question}</p>
                                </div>
                                <div className="px-4 pb-4">
                                    <div className="flex justify-center mt-4">
                                        <button
                                            onClick={() => scrollToDetail(key)}
                                            className="bg-green-200 hover:bg-green-300 text-green-800 font-bold py-2 rounded-full text-center px-6 transition-colors duration-300 ease-in-out"
                                        >
                                            {buttonText}
                                        </button>
                                    </div>
                                    <div className="flex justify-center mt-4">
                                        <button
                                            onClick={openRegistration}
                                            className="bg-blue-200 hover:bg-blue-300 text-blue-800 font-bold text-center rounded-full py-2 px-6 my-2 transition-colors duration-300 ease-in-out">
                                            Sign up
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <Registration isOpen={isRegistrationOpen} onClose={closeRegistration} />
        </div>
    );
}




