import Modal from 'react-modal';
import { XMarkIcon } from '@heroicons/react/24/outline';

Modal.setAppElement('#app');

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '400px',
        width: '90%',
        borderRadius: '0.5rem',
        border: 'none',
        padding: '2rem',
        boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)',
        zIndex: 50,
    },
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 40,
    },
};

export default function Registration({ isOpen, onClose }) {
    const redirectPath = '/members/';
    return (
        <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles}>
            <div className="text-right">
                <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
            </div>
            <div className="text-center">
                <h2 className="text-xl font-semibold text-gray-900">Sign Up</h2>
            </div>
            <div className="mt-4 text-center">
                <a href={'/register/google?redirect_uri=' + encodeURIComponent(window.location.protocol + "//" + window.location.host + redirectPath)}
                    className="group flex items-center justify-center px-4 py-2 border border-amber-900 rounded-full transition duration-300 hover:border-amber-600 focus:bg-blue-50 active:bg-blue-100">
                    <img src="https://tailus.io/sources/blocks/social/preview/images/google.svg" className="w-5 h-5 mr-2" alt="Google logo" />
                    <span className="font-semibold tracking-wide text-amber-900">Sign up with Google</span>
                </a>
                <div className="mt-4">
                    <p className="mt-4 text-xs text-gray-500">
                        The direct sign-up feature is currently under development and will be available soon.
                    </p>
                </div>
                <div className="mt-4 text-xs text-gray-500">
                    By signing up, you agree to our <a href="/terms" className="text-blue-600 hover:underline">Terms of Service</a> and <a href="/privacy" className="text-blue-600 hover:underline">Privacy Policy</a>.
                </div>
            </div>
        </Modal>
    );
}