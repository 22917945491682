const resetZoom = () => {
  const viewport = document.querySelector("meta[name=viewport]");
  viewport.setAttribute(
    "content",
    "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
  );
  setTimeout(() => {
    viewport.setAttribute(
      "content",
      "width=device-width, initial-scale=1"
    );
  }, 100);
};

const useScreenZoom = () => {
  return { resetZoom };
};

export default useScreenZoom;
