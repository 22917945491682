import { useState, useEffect } from 'react';
import useInterval from '../hooks/useInterval';
import handleFetch from '../utils/handleFetch';
import { useAuth } from '../hooks/useAuth';

export default function useFetchInterval(url, successCallback = null, errorCallback = null, initialLoading, interval, dependencies = []) {
  const [loading, setLoading] = useState(true);
  const [intervalLoading, setIntervalLoading] = useState(true);
  const [data, setData] = useState();
  const [error, setError] = useState();
  const { logout } = useAuth();

  const fetchHandler = async () => {
      await handleFetch(
        url,
        {},
        setIntervalLoading,
        (result) => {
          setData(result);
          if (successCallback) successCallback(result);
        },
        (newError) => {
          setError(newError);
          if (errorCallback) errorCallback(newError);
        },
        logout);
  };

  useInterval(fetchHandler, interval, false, [url, ...dependencies]);

  useEffect(() => {
    if (initialLoading && !intervalLoading) {
      setLoading(intervalLoading);
    }
  }, [intervalLoading, initialLoading]);

  return { loading, data, error };
}