export default async function handleFetch(url, options, setLoading, successCallback, errorCallback, logout) {

  if (!url) return;
  setLoading(true);

  try {
    const response = await fetch(url, {
      headers: { 'Content-Type': 'application/json', ...options.headers },
      ...options,
    });

    if (response.status === 401) {
      logout();
    } else if (response.status === 504) {
      throw new Error('The server took too long to respond. Please try again later.');
    } else if (!response.ok) {
      throw new Error(`Server responded with status: ${response.statusText}`);
    }
    const result = await response.json();
    successCallback(result);

  } catch (error) {
    errorCallback(error.message);
  } finally {
    setLoading(false);
  }
}
