import { useEffect } from 'react';
import { default as i18nConfig } from '../i18n';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

export default function useComponentTranslation(namespace, translations) {


  const { i18n } = useTranslation();
  useEffect(() => {
    if (i18n.language == 'en') {
      moment.locale(i18n.language);
    } else {
      import(`moment/locale/${i18n.language}`)
        .then(() => {
          moment.locale(i18n.language);
        })
        .catch(err => console.error('Failed to load moment locale:', err));
    }
  }, [i18n.language]);


  for (const [lang, translation] of Object.entries(translations)) {
    i18nConfig.addResourceBundle(lang, namespace, translation);
  }

  return useTranslation();
};
