import { useContext, useEffect, useState } from "react";
import { CommunityContext, SetCommunityContext } from "../../community-context";
import { MemberContext, SetMemberContext } from "../../member-context";
import CommunitySelector from "../../components/CommunitySelector";
import MemberSelector from "../../components/MemberSelector";
import { UserIcon, PlusIcon } from '@heroicons/react/20/solid';
import UserDropdown from "./UserDropdown.js";
import InviteForm from "./InviteForm.js";
import useFetch from "../../hooks/useFetch.js";
import { useErrors } from "../../hooks/useErrors.js";
import { useAuth } from "../../hooks/useAuth.js";

export default function Users() {
    const currentUser = useAuth().user;
    const community = useContext(CommunityContext);
    const member = useContext(MemberContext);
    const setCommunity = useContext(SetCommunityContext);
    const setMember = useContext(SetMemberContext);
    const [showActionsId, setShowActionsId] = useState(null);
    const [showInviteForm, setShowInviteForm] = useState(false);
    const { data, loading, error } = useFetch(member ? `/api/members/${member.id}/users_and_guests` : null);
    const [usersAndGuests, setUsersAndGuests] = useState([]);
    const errors = useErrors();
    const [isAdmin, setIsAdmin] = useState(false);

    
    useEffect(() => {
        if (data) {
            setUsersAndGuests(data);
            const currentUserData = data.find(user => user.userId === currentUser.id);
            setIsAdmin(currentUserData ? currentUserData.admin : false);
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            errors.add(error);
        }
    }, [error]);

    const addUser = () => {
        setShowInviteForm(prev => !prev);
    };

    const deleteUser = (userId) => {
        fetch(`/api/members/${member.id}/users/${userId}`, { method: 'DELETE' })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(data => {
                        throw new Error(data.message || "Failed to delete user");
                    });
                }
                console.log("User deleted successfully");
                setUsersAndGuests(prevUsersAndGuests => prevUsersAndGuests.filter(usersAndGuests => usersAndGuests.userId !== userId));
            })
            .catch(error => {
                console.error("Failed to delete user", error);
                errors.add(error.message);
            });
    };

    const deleteInvite = (inviteId) => {
        fetch(`/api/members/${member.id}/invites/${inviteId}`, { method: 'DELETE' })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(data => {
                        throw new Error(data.message || "Failed to delete invite");
                    });
                }
                console.log("Invite deleted successfully");
                setUsersAndGuests(prevUsersAndGuests => prevUsersAndGuests.filter(usersAndGuests => usersAndGuests.inviteId !== inviteId));
            })
            .catch(error => {
                console.error("Failed to delete invite", error);
                errors.add(error.message);
            });
    };

    const editUser = (userId) => {

    };

    return (
        <div className="px-4 lg:px-8 py-4">
            <div className="w-full max-w-md mx-auto my-8 p-6 bg-white shadow rounded-lg">
                <h3 className="text-lg md:text-xl font-medium text-amber-900">Users</h3>

                <div className="my-4">
                    <CommunitySelector setCommunity={setCommunity} />
                </div>
                {community && (
                    <div className="my-4">
                        <MemberSelector setMember={setMember} community={community} />
                    </div>
                )}
                {loading && <div className="text-gray-500">Loading...</div>}

                <ul className="divide-y divide-gray-200 my-4">
                    {usersAndGuests && usersAndGuests.map((userOrGuest) => (
                        <li
                            key={userOrGuest.userId ? userOrGuest.userId : userOrGuest.inviteId}
                            className={`relative flex items-center py-2 px-2 ${userOrGuest.status === 'Invited' ? 'bg-gray-100' : 'bg-white'}`}
                            onMouseEnter={() => setShowActionsId(userOrGuest.userId ? userOrGuest.userId : userOrGuest.inviteId)}
                            onMouseLeave={() => setShowActionsId(null)}
                        >
                            <div className="flex-grow flex items-center">
                                <div className="flex flex-col">
                                    <p className="text-sm font-medium text-gray-900">{userOrGuest.fullName}</p>
                                    <p className="text-sm text-gray-500">{userOrGuest.email}</p>
                                </div>
                                {userOrGuest.admin && (
                                    <div className="px-4">
                                        <span className="flex-shrink-0 inline-flex items-center rounded text-xs lg:text-sm font-medium bg-green-200 text-gray-800">
                                            Admin
                                        </span>
                                    </div>
                                )}
                                {userOrGuest.status === 'PENDING' && (
                                    <div className="px-4">
                                        <span className="flex-shrink-0 inline-flex items-center rounded text-xs lg:text-sm font-medium bg-yellow-200 text-gray-800">
                                            Pending
                                        </span>
                                    </div>
                                )}
                            </div>
                            <div className="ml-auto flex-shrink-0">
                                {(userOrGuest.userId === currentUser.id || (isAdmin && !userOrGuest.admin)) && showActionsId === (userOrGuest.userId || userOrGuest.inviteId) ? (
                                    <UserDropdown
                                        onEdit={() => editUser(userOrGuest.userId)}
                                        onDelete={() => userOrGuest.userId ? deleteUser(userOrGuest.userId) : deleteInvite(userOrGuest.inviteId)}
                                        isCurrentUser={userOrGuest.userId === currentUser.id}
                                    />
                                ) : null}
                            </div>
                        </li>
                    ))}
                </ul>
                {member && isAdmin && <div className="flex justify-left my-4">
                    <button
                        onClick={addUser}
                        title="Add user"
                        className="inline-flex items-center bg-green-600 hover:bg-green-700 text-white font-medium px-4 rounded-full"
                    >
                        <div className="flex items-center justify-center rounded-full p-2">
                            <div className="relative flex items-center justify-center">
                                <UserIcon className="text-white h-6 w-6" />
                                <div className="absolute -left-2 -top-1 flex items-center justify-center">
                                    <div className="rounded-full p-1">
                                        <PlusIcon className="text-white h-4 w-4" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span className="ml-2">Add User</span>
                    </button>
                </div>
                }
            </div>
            {showInviteForm && (
                <div className="w-full max-w-md mx-auto mt-8">
                    <InviteForm existingUsers={usersAndGuests} onClose={() => setShowInviteForm(false)} />
                </div>
            )}
        </div>
    );
};
