import React, { useState } from 'react';
import Modal from 'react-modal';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

Modal.setAppElement('#app');

const googleLogo = 'https://tailus.io/sources/blocks/social/preview/images/google.svg';

export default function Connect({ isOpen, onClose, redirectPath }) {
    const navigate = useNavigate();

    const loginUrl = `/login/google?redirect_uri=${encodeURIComponent(redirectPath)}`;
    const signUpUrl = `/register/google?redirect_uri=${encodeURIComponent(redirectPath)}`;

    return (
        <Modal isOpen={isOpen} onRequestClose={onClose} style={{
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: '500px',
                width: '90%',
                borderRadius: '0.5rem',
                border: 'none',
                padding: '2rem',
                boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)',
            },
            overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
        }}>
            <div className="text-right">
                <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
            </div>
            <div className="text-center">
                <h2 className="text-xl font-semibold text-gray-900 mb-4">You're Invited!</h2>
                <p className="mb-6">Join us to start your journey. Choose your path:</p>
                <div className="grid grid-cols-1 gap-4 mb-4">
                    <div className="border-b-2 border-gray-200 pb-4">
                        <h3 className="text-lg font-medium text-gray-800 mb-2">I already have an account</h3>
                        <a href={loginUrl} className="flex items-center justify-center px-4 py-2 border border-amber-900 rounded-full transition duration-300 hover:border-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500">
                            <img src={googleLogo} alt="Google logo" className="w-5 h-5 mr-2" />
                            <span className="font-medium text-amber-900">Log in with Google</span>
                        </a>
                    </div>
                    <div className="pt-4">
                        <h3 className="text-lg font-medium text-gray-800 mb-2">I'm new here</h3>
                        <a href={signUpUrl} className="flex items-center justify-center px-4 py-2 border border-amber-900 rounded-full transition duration-300 hover:border-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500">
                            <img src={googleLogo} alt="Google logo" className="w-5 h-5 mr-2" />
                            <span className="font-medium text-amber-900">Sign up with Google</span>
                        </a>
                    </div>
                </div>
                <p className="text-xs text-gray-500">By proceeding, you agree to our <a href="/terms" className="text-blue-600 hover:underline">Terms of Service</a> and <a href="/privacy" className="text-blue-600 hover:underline">Privacy Policy</a>.</p>
            </div>
        </Modal>
    );
};
   


