import React from 'react';

export default function ChartsLegend({ y }) {
  return (
    <div className='w-max m-auto text-xsm mt-3 md:text-base text-amber-900 text-left flex space-x-10'>
      {Object.keys(y).map(category => (
        <div className='mt-2' key={category}>
          <div>{y[category]['label']}</div>
          <div className='grid justify-center'>
            {Object.values(y[category].fields).map((field, index) => (
              <div key={index} className='ml-2 -my-1 flex flex-col'>
                <div className='flex items-center'>
                  <div className={"w-2 h-2 bg-[" + field.color + "] block"}></div>
                  <div className='ml-1'>{field.label}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

