// source: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
import { useEffect, useRef } from 'react';

export default function useInterval(callback, delay, waitFirstTime = false, dependencies = []) {

  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = async () => {
      if (savedCallback.current) {
        await savedCallback.current();
      }
    };

    if (delay !== null) {
      if (!waitFirstTime) {
        tick();
      }
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay, waitFirstTime, ...dependencies]);
}