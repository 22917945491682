import { Dialog } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

const googleLogo = 'https://tailus.io/sources/blocks/social/preview/images/google.svg';

export default function Connect({ isOpen, onClose, redirectPath, title, description }) {

  const loginUrl = `/login/google?redirect_uri=${encodeURIComponent(redirectPath ? redirectPath : window.location.href)}`;
  const signUpUrl = `/register/google?redirect_uri=${encodeURIComponent(redirectPath ? redirectPath : window.location.href)}`;

  const GoogleButton = ({ url, title }) => (
    <a
      href={url}
      className="flex flex-row items-center justify-center w-full px-4 py-2 border border-black rounded-full transition duration-300 hover:border-amber-600 focus:outline-none"
    >
      <img src={googleLogo} alt="Google logo" className="w-5 h-5 mr-2" />
      <span className="font-bold">{title}</span>
    </a>
  );

  return (
    <Dialog open={isOpen} onClose={() => { if (onClose) onClose(); }} className="relative z-50">
      <div className="fixed inset-0 bg-black/40" aria-hidden="true" />
      <div className="fixed inset-0 flex w-screen items-center justify-center">
        <Dialog.Panel className="w-[90%] max-w-[500px] rounded-lg p-8 bg-white shadow-lg">
          <div className="p-2">
            <div className="text-right">
              <button onClick={onClose} className="text-gray-400 hover:text-gray-500 outline-none">
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <Dialog.Title className="text-xl font-semibold text-gray-900 mb-4 text-center">{title}</Dialog.Title>
            <Dialog.Description className="mb-6">{description}</Dialog.Description>
            <div className="grid grid-cols-1 gap-4 mb-4 max-w-72 w-[90%] mx-auto">
              <div className="border-b-2 border-gray-200 pb-4 w">
                <p className="font-medium text-gray-800 mb-2">I already have an account</p>
                <GoogleButton url={loginUrl} title="Log in with Google" />
              </div>
              <div className="pt-4 p-1">
                <p className="font-medium text-gray-800 mb-2">I'm new here</p>
                <GoogleButton url={signUpUrl} title="Sign up with Google" />
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );


};



