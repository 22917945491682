import { Link, useLocation } from 'react-router-dom';

export default function Whoops404({ message }) {
  let location = useLocation();
  return (
    <div>
      <h1>
        The path {location.pathname} does not exist. Go to <Link to="/" className='font-bold'>Home Page</Link>
      </h1>
    </div>
  );
};