import React, { useState, useEffect } from 'react';
import community from '../images/community.png';
import Registration from '../Registration/Registration';


export default function Invitation({ scrollToDetail }) {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isRegistrationOpen, setIsRegistrationOpen] = useState(false);

  const openRegistration = () => setIsRegistrationOpen(true);
  const closeRegistration = () => setIsRegistrationOpen(false);

  useEffect(() => {
    const img = new Image();
    img.src = community;
    img.onload = () => setImageLoaded(true);
  }, [community]);

  const bgImageClass = imageLoaded ? "bg-[url('routes/WelcomePage/images/community.png')]" : 'bg-black';

  return (
    <>
      <section
        id="invitation"
        className={`relative h-screen bg-no-repeat bg-cover bg-center flex items-center justify-center ${bgImageClass} h-[90vh] mt-[-4rem] pt-[4rem]`}
      >
        {imageLoaded && (
          <>
            <div className="absolute inset-0 bg-[radial-gradient(circle,_rgba(0,0,0,0.5)_30%,_rgba(0,0,0,0.8)_100%)]"></div>
            <div className="relative z-10 text-center text-white p-6">
              <h2 className="text-4xl font-bold mb-4">Empower Your Energy Journey</h2>
              <p className="text-xl mb-4">Optimize your consumption and connect with others.</p>
              <div className="flex justify-center gap-4">
                <button className="text-gray-800 font-bold py-2 px-4 rounded hover:shadow-lg transition duration-300 bg-lime-500 hover:bg-lime-600" onClick={openRegistration}>Get Started</button>
                <button className="text-gray-800 font-bold py-2 px-4 rounded hover:shadow-lg transition duration-300 bg-blue-500 hover:bg-blue-600" onClick={() => scrollToDetail("profile-questions")}>Learn More</button>
              </div>
            </div>
          </>
        )}
      </section>
      <Registration isOpen={isRegistrationOpen} onClose={closeRegistration} />
    </>
  );
}
